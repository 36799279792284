import ListPhoto from './listPhoto'
import PhotoListHolder from './photoListHolder'
import Link from 'next/link'
import PImage from './pImage'
export default class CollectionPreview extends React.Component {


  render() {

    let collection = this.props.collection

    let likeIds = this.props.likeIds



    let photo = collection.preview_photo


    return <div className="col-sm-6 col-md-4 mb-4">
      <Link as={`/collections/${collection.slug}`} href={"/collections?collection_slug=" + collection.slug}>
        <a title={collection.name + " Images"}>
          <div className="collection-wrapper">

            <PImage key={photo.id} photo={photo} url={photo.thumbnail_medium} title={photo.title} />

            <div className="collection-list-mask rounded-lg">
            </div>

            <div className="collection-list-name">
              {collection.name}
            </div>
          </div>


        </a>
      </Link>
    </div>

  }
}
