import Nav from '../components/nav'
import NavPure from '../components/navPure'
import Head from 'next/head'
import Router, { withRouter } from 'next/router'
import Footer from '../components/footer'
import Link from 'next/link'
import { setLocalKeyValue, getLocalKeyValue, getJsonData, setJsonData } from '../storage'
import React from 'react'
import CartContext from '../ctx/cartContext'
import { CART, IS_DEBUG, LIKES, GET_USER_URL, TOKEN, SITE_URL, HOTJAR_SITEID } from '../vars'
import NextSeo from 'next-seo';
import ReactGA from 'react-ga';
import { GET, POST } from '../helpers'
import CookieConsent from "react-cookie-consent";
import WelcomeNotes from '../components/welcomeNote'
import { logPageView, initGA, initFBP, logEvent } from '../components/analytics'

class LayoutBasic extends React.Component {

  constructor(props) {
    super(props)
    this.updatecart = this.updatecart.bind(this)
    this.state = {
      cart: null,
      show_nav_search_bar: (typeof props.defaultNavBar !== "undefined" ? props.defaultNavBar : false),
      anKey: "",
      testDate: null,
      currentCoupon: null,
    }

    this.timer = null;
    // Define the list of coupon codes
    this.coupons = [
      { code: 'PCXY4AUG', discount: '10%', validUntil: new Date('2024-08-31') },
      { code: 'PCXY10DSC', discount: '10%', validUntil: new Date('2024-09-30') },
      { code: 'PCX$OT10', discount: '5%', validUntil: new Date('2024-10-31') },
      { code: 'N0VPH$5', discount: '5%', validUntil: new Date('2024-11-30') },
      { code: 'GETFREE@P!C', discount: '33%', validUntil: new Date('2025-03-31'), text:"Buy 2 & Get 1 Free Image" },
    ];

  }

  componentDidMount() {

    if (!window.GA_INITIALIZED) {
      initGA()
      window.GA_INITIALIZED = true
    }

    logPageView(this.props.title)

    const token = this.props.token || getLocalKeyValue(TOKEN);

    if (token) {
      const existingToken = document.cookie.split('; ').find(row => row.startsWith('token='));
      if (!existingToken || existingToken.split('=')[1] !== token) {
        document.cookie = `token=${token};path=/;`;
        location.reload();
      }
    }



    // let token = this.props.user || getLocalKeyValue(TOKEN)
    //
    // if(token) {
    //     GET(GET_USER_URL, token).then((res)=>{
    //       setJsonData('cart', res.data.cart)
    //         this.setState({logged_in_user:res.data.user, cart:res.data.cart})
    //
    //     })
    // }

    this.updateCoupon();
    this.timer = setTimeout(this.updateCoupon, 5000);


  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.testDate !== this.state.testDate) {
      clearTimeout(this.timer);
      this.timer = setTimeout(this.updateCoupon, 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }



  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.router.asPath !== this.props.router.asPath) {
      logPageView(nextProps.title)
    }
  }

  updatecart(cart, anKey) {
    setJsonData('cart', cart)
    this.setState({ cart: cart, anKey: anKey }) // dummy update to render cart info

  }

  updateNavSearchVisibility = (v) => {
    this.setState({ show_nav_search_bar: !v })
  }


  //------------------Genrate cupon code strat from here-----------------------------------------



  // Function to get the current valid coupon
  getCurrentCoupon = (now) => {
    return this.coupons.find(coupon => now <= coupon.validUntil);
  };

  // Function to set a custom date for testing
  handleDateChange = (event) => {
    this.setState({ testDate: new Date(event.target.value) });
  };

  // Function to update the coupon
  updateCoupon = () => {
    const now = this.state.testDate || new Date(); // Use testDate if set, otherwise use current date
    const coupon = this.getCurrentCoupon(now);
    this.setState({ currentCoupon: coupon });
  };

  //------------------Genrate cupon code end  here-----------------------------------------



  render() {
    let props = this.props
    let title = props.title
    let welcomenote_show = props.welcomenote_show ? false : true
    const { currentCoupon } = this.state;
    if (title) {
      title = <title>{title}</title>
    }
    let cart = this.state.cart || props.cart
    let token = this.state.token || props.token
    let requireNav = props.requireNav || "yes";

    let nav, children
    if (requireNav === "yes") {
      nav = <Nav token={token} logged_in_user={props.user} {...props} />
      children = <div className="topped pt-2">
        {props.children}
      </div>
    }
    else {
      children = props.children
    }


    // ---------coupon code slide start from here 05/08/2024-----------

    let homeBanner

    if (props.router && props.router.asPath !== "/pricing") {

      if (currentCoupon) {
        
      
      // const message = "Use ‘PCXY4AUG’ for 10% discount on any purchase. Offer valid till August 31st, 2024";
      const message = `${currentCoupon?.text + ' - '}Use ‘${currentCoupon.code}’ for ${currentCoupon.discount} discount on any purchase. Offer valid till ${currentCoupon.validUntil.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`;
      const repeatCount = 20; // Define how many times you want to repeat the message

      const messages = Array.from({ length: repeatCount }, (_, index) => (
        <div key={index} className="d-inline-block px-4">{message}</div>
      ));

      homeBanner = (
        <div className="announcement-bar">
          <marquee>
            {/* <div style={{ grid: 'auto / auto-flow max-content', justifyContent: 'center', display: 'grid', gap: '60px', fontWeight: '500', fontSize: '16px' }}> */}
            {/* <div style={{ display: 'inline-block', fontWeight: '500', fontSize: '16px' }}> */}

            {messages}

          </marquee>
        </div>
      );
    }

      // ---------coupon code slide end  here 05/08/2024-----------




      // ------------------------------------------------------------------------------------------------------------------------------------------

      // homeBanner = <Link href="/pricing">
      //   <a className="text-decoration-none bg-home-banner text-white text-center d-block" onClick={(e) => { logEvent("Banner", props.router.asPath) }}>
      //     <span className="d-block d-md-inline  mb-2 mb-md-0">Get Indian Images for as low as Rs.100 with our Value Packs.</span>
      //     <span className="bg-white d-inline d-md-inline text-dark px-3 py-1 text-nowrap rounded-pill ml-2 font-weight-normal">See More</span>
      //   </a>
      // </Link>

      // ------------------------------------------------------------------------------------------------------------------------------------------

    }



    // Define a function to return dynamic SEO data based on the route
    const getSeoData = () => {
      const { pathname } = this.props.router;
      switch (pathname) {
        case '/book_photographer':
          return {
            title: "Photographer for Hire in India",
            description: "Leading Photography Booking Platform, Send a message on Book your Service.",
            openGraph: {
              url: 'https://www.picxy.com/',
              image: {
                url: 'https://www.picxy.com/static/imgv1/picxy_home_2.jpg',
              },
            },
          };

        case '/collections':
          return {
            title: "License Stock Collections",
            description: "All file types & HD. Download Royalty free stock photos, Unleash your creativity with this.",
            openGraph: {
              url: 'https://www.picxy.com/',
              image: {
                url: 'https://www.picxy.com/static/imgv1/picxy_home_2.jpg',
              },
            },
          };

        case '/pricing':
          return {
            title: "Stock Photos, Royalty-free License Prices & Plans",
            description: "Get the best prices on royalty-free stock images tailored to your needs.",
            openGraph: {
              url: 'https://www.picxy.com/',
              image: {
                url: 'https://www.picxy.com/static/imgv1/picxy_home_2.jpg',
              },
            },
          };

        default:
          return {
            title: 'Indian Stock Photo Website | Explore Indian Stock Images from photographers across India',
            description: 'Purchase Indian stock pictures from best photographers across India. Download quality images at affordable rates - Picxy',
            openGraph: {
              url: 'https://www.picxy.com/', // default website URL
              image: {
                url: 'https://www.picxy.com/static/imgv1/picxy_home_2.jpg', // default image URL
              },
            },
          };
      }
    };

    const seoData = getSeoData();


    return <div>
      {/* <NextSeo
        config={{
          title: 'Indian stock photo website | Explore Indian Stock Images from photographers across India',
          description: 'Purchase Indian stock pictures from best photographers across India. Download quality images at affordable rates - Picxy',
          openGraph: {
            url: 'http://localhost:3004/', // Update with your website URL
            image: {
              url: 'http://localhost:3004/static/imgv1/picxy_home_2.jpg', // Update with your image URL
            },
          },
        }}
      /> */}

      <NextSeo config={seoData} /> {/* Use the SEO config */}
      <CartContext.Provider value={{
        cart: cart,
        anKey: this.state.anKey,
        updatecart: this.updatecart,

        show_nav_search_bar: this.state.show_nav_search_bar,
        updateNavSearchVisibility: this.updateNavSearchVisibility
      }}>
        <Head>
          <meta property="fb:app_id" content="474857699568027" />
          <meta name="google-site-verification" content="dz8CN-UQwFurVWE2GRrkXMWTfVoo2bDBGoroQUqpDVk" />
          <link rel="preconnect" href="https://images.picxy.com" crossOrigin="true" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
          <link rel="preconnect" href="https://fonts.googleapis.com" crossOrigin="true" />
          <link rel="preconnect" href="https://storage.googleapis.com" crossOrigin="true" />
          <link rel="preconnect" href="https://www.google-analytics.com" crossOrigin="true" />

          <link rel="shortcut icon" type="image/png" href="https://www.picxy.com/static/img/favicon.png" />
          {title}
          <link href="https://fonts.googleapis.com/css2?family=Work+Sans&display=swap" rel="stylesheet" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.6.0/css/all.min.css" integrity="sha512-Kc323vGBEqzTmouAECnVceyQqyqdsSiqLQISBL29aUW4U/M7pSPA/gEUZQqv1cwx4OnYxTxve5UMg5GT6L4JJg==" crossOrigin="anonymous" referrerpolicy="no-referrer" />
        </Head>


        {homeBanner}


        {nav}

        <WelcomeNotes user={props.user} show={welcomenote_show} />
        {/* <input type="date" onChange={this.handleDateChange} /> */}
        {children}

        <Footer token={token} />

      </CartContext.Provider>

    </div>

  }


}
export default withRouter(LayoutBasic)
