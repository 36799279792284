import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { UPLOAD_PICXY } from '../vars'

export default function Footer(props) {

  let token = props.token

  let rpayJs
  if (token) {
    rpayJs = <><script src="https://checkout.razorpay.com/v1/checkout.js"></script>
      {/* <script src="https://js.stripe.com/v3/"></script> */}
    </>
  }


  return <div>
    <footer>
      {/* <!-- Section: Links  --> */}
      <section className="footerbg">
        <div className="container">
          {/* <!-- Grid row --> */}
          <div className="row mt-3 justify-content-between">
            {/* <!-- Grid column --> */}
            <div className="col-md-4 col-lg-4 col-xl-4 mb-lg-4 pe-lg-5">
              {/* <!-- Content --> */}
              <h6>
                <img className="footerlogo" src="/static/img_new/logo.png" />
              </h6>
              <p className="footet-text logotext">
                Picxy is the Largest Stock Photo Company in South Asia aiming to provide you with authentic images exclusively, pertaining to South Asian Context as a whole. We are leaders in offering imagery of South Asia but have already set out to expand to every part of the continent!
              </p>
            </div>
            {/* <!-- Grid column --> */}

            {/* <!-- Grid column --> */}
            <div className="col-md-2 col-6 col-lg-2 col-xl-2 ps-xl-5">
              {/* <!-- Links --> */}
              <h6 className="footerhead mb-4">Resources</h6>
              <ul className='list-unstyled'>
                <li><a href="/meetups" className="footet-text" rel="nofollow">Meetups</a></li>
                <li><a href="/about_us" className="footet-text" rel="nofollow">About Us</a></li>
                <li><a href="/contact_us" className="footet-text" rel="nofollow">Contact Us</a></li>
                <li><a href="/feedback" className="footet-text" rel="nofollow">Feedback</a></li>
              </ul>
            </div>
            {/* <!-- Grid column --> */}

            {/* <!-- Grid column --> */}
            <div className="col-md-3 col-6 col-lg-3 col-xl-2">
              {/* <!-- Links --> */}
              <h6 className="footerhead mb-4">Buy On Picxy</h6>
              <ul className='list-unstyled'>
                <li><a href="/pricing" className="footet-text">Pricing</a></li>
                <li><a href="/photos" className="footet-text">Explore Photos</a></li>
                <li><a href="/license_agreement" className="footet-text" rel="nofollow">License Agreement</a></li>
                <li><a href="/license_agreement#paymentsOrRefunds" className="footet-text" rel="nofollow">Refund Policy</a></li>
                <li><a href="https://picxy.com/privacy_policy" className="footet-text" rel="nofollow">Privacy Policy</a></li>
              </ul>
            </div>
            {/* <!-- Grid column --> */}

            {/* <!-- Grid column --> */}
            <div className="col-md-3 col-lg-3 col-xl-2">
              {/* <!-- Links --> */}
              <h6 className="footerhead mb-4">Legal</h6>

              <ul className='list-unstyled'>
                <li><a href="https://upload.picxy.com/" className="footet-text" rel="nofollow" target="_blank">Sell On Picxy</a></li>
                <li><a href="/terms_and_conditions" className="footet-text" rel="nofollow">Terms & Conditions</a></li>
                <li><a href="/submission_guidelines" className="footet-text" rel="nofollow">Submission Guidelines</a></li>
                {/* <li><a href="/contests" className="footet-text" rel="nofollow">Contests</a></li> */}
              </ul>

              {/* <p>
                <a href="#!" className="footet-text">Privacy Policy</a>
              </p>
              <p>
                <a href="#!" className="footet-text">Cookie Policy</a>
              </p> */}
            </div>
            {/* <!-- Grid column --> */}
          </div>
          {/* <!-- Grid row --> */}
        </div>
      </section>
      {/* <!-- Section: Links  --> */}

      {/* <!-- Copyright --> */}
      <section className="subfooter">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-12 col-md-6 col-lg-6 copyright"> ©{new Date().getFullYear()} Picxy Pro Technologies </div>
            <div className="col-sm-12 col-md-6 col-lg-6 text-end">
              <div className="social-icons">
                <a href="https://www.facebook.com/picxydotcom/" target='_blank'>
                  <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.3 10.35H8.55L9.45 6.75H6.3V4.95C6.3 4.023 6.3 3.15 8.1 3.15H9.45V0.126C9.1566 0.0873001 8.0487 0 6.8787 0C4.4352 0 2.7 1.4913 2.7 4.23V6.75H0V10.35H2.7V18H6.3V10.35Z" fill="#2F328C" />
                  </svg>
                </a>
                <a href="https://www.instagram.com/picxydotcom/" target='_blank'>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 11C3 7.229 3 5.343 4.172 4.172C5.343 3 7.229 3 11 3H13C16.771 3 18.657 3 19.828 4.172C21 5.343 21 7.229 21 11V13C21 16.771 21 18.657 19.828 19.828C18.657 21 16.771 21 13 21H11C7.229 21 5.343 21 4.172 19.828C3 18.657 3 16.771 3 13V11Z" stroke="#2F328C" strokeWidth="2" />
                    <path d="M16.5 9C17.3284 9 18 8.32843 18 7.5C18 6.67157 17.3284 6 16.5 6C15.6716 6 15 6.67157 15 7.5C15 8.32843 15.6716 9 16.5 9Z" fill="#2F328C" />
                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#2F328C" strokeWidth="2" />
                  </svg>
                </a>
                <a href="https://x.com/picxydotcom" target='_blank'>
                  <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.175 0H16.9354L10.9054 6.90943L18 16.3131H12.4457L8.09229 10.611L3.11657 16.3131H0.353571L6.80271 8.92029L0 0.0012857H5.69571L9.62486 5.21229L14.175 0ZM13.2043 14.6571H14.7343L4.86 1.56986H3.21943L13.2043 14.6571Z" fill="#2E318C" />
                  </svg>
                </a>
                <a href="https://www.linkedin.com/company/picxy/" target='_blank'>
                  <svg width="19" height="17" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.71749 1.88874C4.71724 2.38967 4.518 2.86999 4.16361 3.22403C3.80922 3.57806 3.3287 3.77682 2.82777 3.77657C2.32684 3.77632 1.84652 3.57708 1.49248 3.22269C1.13845 2.8683 0.939691 2.38779 0.939942 1.88685C0.940192 1.38592 1.13943 0.905601 1.49382 0.551564C1.84821 0.197528 2.32872 -0.00122679 2.82966 -0.000976326C3.33059 -0.000725859 3.81091 0.198509 4.16495 0.5529C4.51898 0.907291 4.71774 1.38781 4.71749 1.88874ZM4.77415 5.1752H0.996605V16.9989H4.77415V5.1752ZM10.7427 5.1752H6.98401V16.9989H10.7049V10.7943C10.7049 7.33785 15.2096 7.01676 15.2096 10.7943V16.9989H18.9399V9.50994C18.9399 3.68307 12.2726 3.90028 10.7049 6.76177L10.7427 5.1752Z" fill="#2F328C" />
                  </svg>
                </a>

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Copyright --> */}
    </footer>

    {rpayJs}

  </div>
}
