import Modal from 'react-bootstrap/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faExternalLinkSquareAlt, faInfo, faTimesCircle} from '@fortawesome/free-solid-svg-icons'
import {ACCEPT_TERMS_URL, TOKEN} from '../vars'
import {POST} from '../helpers'
import {setLocalKeyValue, getLocalKeyValue} from '../storage'

export default class WelcomeNotes extends React.Component {


  handleAcceptTerms = (e) =>{
    let token =  getLocalKeyValue(TOKEN)
    e.preventDefault()
    POST(ACCEPT_TERMS_URL, token, {accept_terms:"true"}).then((response)=>{
      if(response.status===200) {
        window.location.reload(true)
      }
    })
  }


  render() {

    let user = this.props.user
    let show = this.props.show
    let content

    if(user&&show) {

      const is_terms_accepted = user.accept_terms

      if(!is_terms_accepted) {
        content = <Modal  show={true}>
            <Modal.Body className="px-3 py-4 p-sm-5 ">
                <h3 className="text-center text-muted">Welcome to Picxy</h3>
                  <div className="mt-5">
                  <div className="text-center"><FontAwesomeIcon icon={faImage} style={{fontSize:40, color:'#d8d8d8'}}/></div>
                  <div className="mb-0 text-center">A photograph has picked up a fact of life, and that fact will live forever.</div>
                  <div className="text-right font-italic">~ Raghu Rai</div>
                  </div>
                <div className="pt-5 mt-3 text-center">
                <div className="text-muted small mb-3">By clicking Continue, you agree to <a href="/terms_and_conditions">Picxy Terms & Conditions</a></div>
                <button className="btn btn-primary" onClick={this.handleAcceptTerms}>Continue</button>
                </div>
            </Modal.Body>


        </Modal>
      }
    }

    return <>
    {content}
    </>

  }
}
