import slugify from 'slugify'
import titleCase from 'title-case'
import Link from 'next/link'
import LayoutBasic from '../layouts/layoutBasic'
import SearchBar, { SearchComp } from '../components/searchBar'
import SearchInput from '../components/searchInput'
import Pagination, { SimplePager } from '../components/pagination'
import CollectionPreviewSingle from '../components/collectionPreviewSingle'
import { withRouter } from 'next/router'
import { COLLECTIONS_URL, TOKEN, GET_USER_URL, COLLECTION_PHOTOS_URL, HOME_DATA_URL } from '../vars'
import { setLocalKeyValue, getLocalKeyValue } from '../storage'
import ListPhoto from '../components/listPhoto'
import PhotoListHolder from '../components/photoListHolder'
import { GET } from '../helpers'
import axios from 'axios'
import { logEvent } from '../components/analytics'
import { SeoMetaCollection, SeoMetaCollections } from '../components/seoMeta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { SeeMore } from '../components/utils'

class Collections extends React.Component {


  static async getInitialProps(props) {


    let token = process.browser ? getLocalKeyValue(TOKEN) : props.query.sec_token

    let url = COLLECTIONS_URL + '?'
    // let url = 'https://azure-picxy-api.picxy.com/get_collections/' + '?'

    const collection_slug = props.query.collection_slug
    if (collection_slug) {
      url = COLLECTION_PHOTOS_URL + '?'
    }

    const search_term = props.query.search
    if (search_term) {
      url = url + '&search=' + slugify(search_term, '+')
    }

    if (props.query.page) {
      url = url + '&page=' + props.query.page
    }
    else {
      url = url + '&page=1'
    }

    if (collection_slug) {
      url = url + '&slug=' + collection_slug
    }

    const res = await GET(url)
    const data = await res.data
    const feature_collection_Response = GET(HOME_DATA_URL);
    // const feature_collection_Response = GET('https://azure-picxy-api.picxy.com/get_home_data/');
    const feature_collection_data = (await feature_collection_Response).data

    let infoRequest, infoResponse = {}
    if (token) {
      try {
        infoRequest = await GET(GET_USER_URL, token)
        infoResponse = await infoRequest.data
      } catch (e) {

      }
    }

    return {
      photos: data.results,
      collections: data.collections,
      feature_collections: feature_collection_data.collections,
      count: data.count,
      likes: infoResponse.likes,
      cart: infoResponse.cart,
      token: token,
      collection: data.collection,
      user: infoResponse.user,
      total_pages: data.total_pages,
      next_page: data.next_page,
      previous_page: data.previous_page,
      current_page: data.page,
      total_pages: data.total_pages,
      pagination: {
        page: data.page,
        total_pages: data.total_pages
      },
      search_term: search_term,
      related_collections: data.related_collections,
    }

  }

  render() {

    let photos = this.props.photos
    let collections = this.props.collections
    let feature_collections = this.props.feature_collections
    let content
    let likeIds = []
    if (this.props.likes) {
      likeIds = this.props.likes
    }
    if (photos) {
      content = photos.map((photo, id) => {
        const liked = (likeIds.indexOf(photo.id) > -1)
        return <ListPhoto titlePrefix={"Image of "} key={id} photo={photo} liked={liked} reference={this.props.router.asPath} />
      })
    }


    let title = "Picxy Photo Collections"
    let collection, collectionRender, defaultNavBar = true, searchComp = <SearchComp />
    let breadcrumbs
    if (this.props.collection) {
      breadcrumbs = <nav aria-label="Breadcrumb" >
        <ol className="breadcrumb bg-white ps-0 mb-0 py-2">
          <li className="breadcrumb-item me-2">
            <Link href="/">
              <a>Home</a>
            </Link>
          </li>
          <li>
            <FontAwesomeIcon icon={faChevronRight} size="xs" className="text-muted mx-2" /> <Link href="/collections">
              <a className="text-normal">Collections</a>
            </Link>
          </li>
        </ol>
      </nav>

      collection = this.props.collection
      let description
      if (collection.description) {
        description = <p className="text-muted"><SeeMore content={collection.description} /> </p>
      }
      title = "Download " + collection.name + ' images | ' + this.props.count + ' HD pictures and stock photos'
      collectionRender = <div className="container mb-3">
        {breadcrumbs}
        <h1 className="">{collection.name} Images</h1>
        <div className="mb-3 small">
          {this.props.count} <span className="text-lowercase">{collection.name}</span> stock photos {this.props.current_page > 1 ? " | page " + this.props.current_page : ""}
        </div>
        {description}

      </div>
      defaultNavBar = false
      searchComp = null

    }

    if (collections) {
      // let collectionsRender = collections.map((collection, index) => {
      //   if (collection.preview_photo) {
      //     return <CollectionPreviewSingle key={index} router={this.props.router} collection={collection} likeIds={likeIds} />
      //   }
      // })


      let accumulatedItems = [];
      if (this.props.current_page === 1) {
        // First page: Combine feature_collections and collections
        accumulatedItems = feature_collections ? feature_collections.slice(0, 6) : [];
        const existingIds = new Set(accumulatedItems.map((item) => item.id));
        const uniqueCollectionItems = collections
          ? collections.filter((item) => !existingIds.has(item.id))
          : [];
        accumulatedItems = accumulatedItems.concat(
          uniqueCollectionItems.slice(0, 48 - accumulatedItems.length)
        );
      } else {
        // Subsequent pages: Render only collections
        accumulatedItems = collections || [];
      }

      // Render the items
      const collectionsRender = accumulatedItems.map((collection, index) => {
        if (collection.preview_photo) {
          return (
            <CollectionPreviewSingle
              key={index}
              router={this.props.router}
              collection={collection}
              likeIds={likeIds}
            />
          );
        }
      });

      let collection_text = "Stock Image Collections"
      if (this.props.search_term) {
        collection_text = <>
          {"Results matching " + titleCase(this.props.search_term)}
        </>
      }

      return <LayoutBasic title={title} {...this.props} navSearchBtn={'no'} defaultNavBar={true}>
        {collections.length >= 1 && <SeoMetaCollections {...this.props} />}
        <main>
          <div className="container mt-5 pt-3 mb-3">
            {/* <h1 className="text-center ">{collection_text}</h1> */}
          </div>
          <div className="container">
            <div className="row justify-content-center mb-5">
              <div className="col-md-8">
                {/* <SearchInput type="collection" {...this.props} /> */}
              </div>
            </div>
            <div className="row">
              {collectionsRender.length >= 1 ? collectionsRender : <span>No Collections found</span>}
            </div>
            <div className="row ">
              <div className="col text-sm-end">
                <SimplePager root_page="https://www.picxy.com/collections" {...this.props} />
              </div>
            </div>
          </div>
        </main>

      </LayoutBasic>
    }
    else {

      if (this.props.current_page && this.props.current_page > 1) {
        title = title + ' page ' + this.props.current_page
      }
      let related_collection_res = this.props.related_collections
      let related_collections_render
      if (related_collection_res && related_collection_res.length) {
        let related_collections = related_collection_res.slice(0, 10).map((col) => {
          return <Link as={`/collections/${col.slug}`} href={"/collections?collection_slug=" + col.slug} key={col.id}>
            <a className="me-2 p-1 pe-2 mb-2 badge badge-pill badge-light border d-inline-block" title={`${col.name} Images`} onClick={(e) => { logEvent("Search", "Search to Collection", col.name) }}>
              <img src={col.preview_photo.thumbnail_small} className="img-fluid rounded-circle me-1 " alt={col.name} width="35" />
              <span style={{ color: '#55595C' }}>{col.name}</span>
            </a>
          </Link>
        })
        let see_more
        if (related_collection_res.length > 10) {
          see_more = <Link href={"/collections?search=" + slugify(collection.name, '+')}>
            <a onClick={(e) => { logEvent("See More", "Collection", collection.name) }}> see more</a>
          </Link>
        }

        related_collections_render = <>
          {related_collections}
          {/*<div className="d-blcok d-md-none">
                  <span className="me-2 py-1">Related Collections:</span>

              </div>
              <div className="d-none d-md-block">
                <div class="row no-gutters">
                  <div class="col col-auto me-2 py-1">Related Collections:</div>
                  <div class="col">{related_collections}</div>
                </div>
              </div>*/}
        </>
      }
      return <LayoutBasic title={title} {...this.props} navSearchBtn={'no'} defaultNavBar={true}>
        <SeoMetaCollection {...this.props} />
        {searchComp}
        <main>
          {collectionRender}
          <div className="container mb-4">
            {related_collections_render}
          </div>
          <PhotoListHolder>
            {content}
          </PhotoListHolder>
        </main>

        <div className="container">
          <div className="row ">
            <div className="col text-sm-end">
              <SimplePager root_page={"https://www.picxy.com/collections/" + collection.slug} {...this.props} />
            </div>
          </div>
        </div>
      </LayoutBasic>

    }

    return null

  }
}


export default withRouter(Collections)
