import ReactGA from 'react-ga'
import Router from 'next/router'
import {IS_DEBUG} from '../vars'
import React from 'react'
import Head  from 'next/head'
export const initGA = () => {
  if(IS_DEBUG) {
      ReactGA.initialize('G-HVEWBFJ9Y2')
  }
  else {
      ReactGA.initialize('G-HVEWBFJ9Y2')
  }
}
export const logPageView = (title) => {

  if(title) {
      ReactGA.pageview(Router.asPath, [], title)
  }
  else {
    ReactGA.pageview(Router.asPath)
  }
  if (typeof window !== "undefined") {
    if (window.fbq != null) {
      window.fbq('track', 'PageView')
    }
  }
}

export const logCoversion = (category='', action='', label='') => {
    ReactGA.event({
      category:category,
      action:action,
      label: label,
      value:1

     })
}

export const logEvent = (category = '', action = '', label='') => {

  if(label && category && action) {
    ReactGA.event({
      category:category,
      action:action,
      label: label
    })
  }
  else if (category && action) {
    ReactGA.event({
      category:category,
      action:action
    })
  }

}
export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({
      description: description,
      fatal: fatal
     })
  }
}


export const Pixel = ()=>{
  return <><Head>
    <React.Fragment>

    <script dangerouslySetInnerHTML={{ __html: `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1688492228287247');
fbq('track', 'PageView');` }}/>

  </React.Fragment>
  </Head>
  <noscript dangerouslySetInnerHTML={{ __html: `<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1688492228287247&ev=PageView&noscript=1"
/>` }}
    />
  </>
}
