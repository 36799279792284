import {useState} from 'react'

export default function Loader(props){

  let type = props.type || 'spinner-border'
  let style = props.style || 'text-primary'


  return <div className="text-center"><div className={type+' '+style} role="status">
<span className="sr-only">Loading...</span>
</div>
</div>

}


export function SeeMore(props){

  const [showMore, setShowMore] = useState(false)

  const splitter = props.splitter || '. '
  const content = props.content

  const splitAt = props.splitAt || 2

  const splitContent = content.split(splitter)

  let resultantContent
  if(showMore){
    resultantContent = props.content
  }
  else {
      resultantContent  = <>
      <span>{splitContent.slice(0,splitAt).join(splitter)}</span>
      <span className="d-none">{splitContent.slice(splitAt+1, splitContent.length)}</span>
      </>
  }

  let seeMoreBtn
  if(splitContent.length > splitAt) {
    seeMoreBtn  =<button className="btn btn-sm btn-link ml-2" onClick={()=>{setShowMore(true)}}>Read More</button>

    if(showMore) {
      seeMoreBtn = <button className="btn btn-sm btn-link ml-2" onClick={()=>{setShowMore(false)}}>Read Less</button>
    }
  }

  return <>
  {resultantContent}
  {seeMoreBtn}
  </>

}
