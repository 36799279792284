import Logo from './logo'
import Link from 'next/link'
import Router from 'next/router';
import CartContext from '../ctx/cartContext'
import { SearchCompThin, SearchComp } from './searchBar'
import { GET_CART_DETAIL_URL, TOKEN, CART, LOGOUT_URL, HOME_DATA_URL, COLLECTIONS_URL, COLLECTION_PHOTOS_URL } from '../vars'
import { getLocalKeyValue, setLocalKeyValue, removeLocalKey } from '../storage'
import { Animated } from "react-animated-css";
import { POST, GET } from '../helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faUpload, faBars, faSearch } from '@fortawesome/free-solid-svg-icons'
import collections from '../pages/collections'

export function PLink(props) {

  const router = props.router
  const path = props.path
  const className = props.className
  const text = props.text
  const target = props.target

  if (router && router.pathname === path) {
    return <a href={path} className={className}>
      {text}
    </a>
  }

  return <Link href={path}>
    <a className={className} target={target}>
      {text}
    </a>
  </Link>
}

export class NavDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  render() {
    const { isDropdownOpen } = this.state;
    const { router, feature_collection, collections = [] } = this.props; // Ensure collections has a default empty array

    // Step 1: Get the first 6 unique items from feature_collection
    let accumulatedItems = feature_collection ? feature_collection.slice(0, 6) : [];

    // Step 2: Add unique items from collections that aren’t in feature_collection, if collections is defined
    const existingIds = new Set(accumulatedItems.map((item) => item.id));
    const uniqueCollectionItems = collections
      ? collections.filter((item) => !existingIds.has(item.id))
      : [];

    // Combine both arrays up to a max of 20 items
    accumulatedItems = accumulatedItems.concat(
      uniqueCollectionItems.slice(0, 20 - accumulatedItems.length)
    );

    return (
      <li
        className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav"
        onMouseEnter={() => this.toggleDropdown(true)}
        onMouseLeave={() => this.toggleDropdown(false)}
      >
        <a className="nav-link collection2" href="/collections">
          Collections
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.53124 11.7812C9.39059 11.9218 9.19986 12.0008 9.00099 12.0008C8.80212 12.0008 8.61139 11.9218 8.47074 11.7812L4.22799 7.53849C4.15636 7.4693 4.09922 7.38655 4.05991 7.29504C4.02061 7.20354 3.99992 7.10513 3.99905 7.00554C3.99819 6.90596 4.01716 6.8072 4.05487 6.71502C4.09258 6.62285 4.14827 6.53911 4.21869 6.46869C4.28911 6.39827 4.37285 6.34258 4.46502 6.30487C4.5572 6.26716 4.65596 6.24819 4.75554 6.24905C4.85513 6.24992 4.95354 6.27061 5.04504 6.30991C5.13655 6.34922 5.2193 6.40636 5.28849 6.47799L9.00099 10.1905L12.7135 6.47799C12.8549 6.34137 13.0444 6.26578 13.241 6.26748C13.4377 6.26919 13.6258 6.34807 13.7649 6.48713C13.9039 6.62618 13.9828 6.81429 13.9845 7.01094C13.9862 7.20759 13.9106 7.39704 13.774 7.53849L9.53124 11.7812Z"
              fill="white"
            />
          </svg>
        </a>

        {isDropdownOpen && (
          <div
            className="dropdown-menu custom-dropdown-menu show"
            style={{ width: '200px' }}
          >
            <div className="custom-grid">
              {/* Render the combined items, ensuring no duplicates */}
              {accumulatedItems.map((item) => (
                <PLink
                  key={item.id}
                  router={router}
                  path={`/collections/${item.slug}`}
                  className="dropdown-item"
                  text={item.name}
                />
              ))}
            </div>

            <hr className="my-2" />

            {/* Show More button at the end */}
            <button
              onClick={() => (window.location.href = '/collections')}
              className="dropdown-item show-more"
            >
              Show More
            </button>
          </div>
        )}
      </li>
    );
  }
}


// export class NavDropdown extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isDropdownOpen: false,
//     };
//   }

//   toggleDropdown = () => {
//     this.setState((prevState) => ({
//       isDropdownOpen: !prevState.isDropdownOpen,
//     }));
//   };

//   render() {
//     const { isDropdownOpen } = this.state;
//     const { router, collections = [] } = this.props; // Ensure collections has a default empty array

//     // Display only collections (first 20 items if more are available)
//     const displayedCollections = collections.slice(0, 20);

//     return (
//       <li
//         className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav"
//         onMouseEnter={() => this.toggleDropdown(true)}
//         onMouseLeave={() => this.toggleDropdown(false)}
//       >
//         <a className="nav-link collection2" href="/collections">
//           Collections
//           <svg
//             width="18"
//             height="18"
//             viewBox="0 0 18 18"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               fillRule="evenodd"
//               clipRule="evenodd"
//               d="M9.53124 11.7812C9.39059 11.9218 9.19986 12.0008 9.00099 12.0008C8.80212 12.0008 8.61139 11.9218 8.47074 11.7812L4.22799 7.53849C4.15636 7.4693 4.09922 7.38655 4.05991 7.29504C4.02061 7.20354 3.99992 7.10513 3.99905 7.00554C3.99819 6.90596 4.01716 6.8072 4.05487 6.71502C4.09258 6.62285 4.14827 6.53911 4.21869 6.46869C4.28911 6.39827 4.37285 6.34258 4.46502 6.30487C4.5572 6.26716 4.65596 6.24819 4.75554 6.24905C4.85513 6.24992 4.95354 6.27061 5.04504 6.30991C5.13655 6.34922 5.2193 6.40636 5.28849 6.47799L9.00099 10.1905L12.7135 6.47799C12.8549 6.34137 13.0444 6.26578 13.241 6.26748C13.4377 6.26919 13.6258 6.34807 13.7649 6.48713C13.9039 6.62618 13.9828 6.81429 13.9845 7.01094C13.9862 7.20759 13.9106 7.39704 13.774 7.53849L9.53124 11.7812Z"
//               fill="white"
//             />
//           </svg>
//         </a>

//         {isDropdownOpen && (
//           <div
//             className="dropdown-menu custom-dropdown-menu show"
//             style={{ width: '200px' }}
//           >
//             <div className="custom-grid">
//               {/* Render only collection items */}
//               {displayedCollections.map((item) => (
//                 <PLink
//                   key={item.id}
//                   router={router}
//                   path={`/collections/${item.slug}`}
//                   className="dropdown-item"
//                   text={item.name}
//                 />
//               ))}
//             </div>

//             <hr className="my-2" />

//             {/* Show More button at the end */}
//             <button
//               onClick={() => (window.location.href = '/collections')}
//               className="dropdown-item show-more"
//             >
//               Show More
//             </button>
//           </div>
//         )}
//       </li>
//     );
//   }
// }






export default class Nav extends React.Component {


  constructor() {
    super()
    this.logout = this.logout.bind(this)
    this.checkUser = this.checkUser.bind(this)
    this.state = { show_mob_search_bar: false, show_d1_menu: false, show_d2_menu: false, isSticky: false, featured_collections: [], collections: [] }
  }

  toggleMobileSearchBar = (e) => {
    this.setState({ show_mob_search_bar: !this.state.show_mob_search_bar })
  }
  checkUser() {
    let token = getLocalKeyValue(TOKEN)
    if (token) {
      this.setState({ token: token })
    }
  }

  deleteCookie = (name) => {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;';

  }

  logout(e) {
    e.preventDefault();
    POST(LOGOUT_URL, getLocalKeyValue(TOKEN)).then((res) => {

      removeLocalKey(TOKEN)
      removeLocalKey(CART)
      var cookies = document.cookie.split(";")
      if (cookies.length) {
        cookies.forEach((cookie) => {
          var c_name = cookie.split("=")[0].trim()
          if (c_name === "token") {
            this.deleteCookie(c_name)
          }
        })
        window.location.href = "/"
      }
    }).catch((err) => {
      removeLocalKey(TOKEN)
      removeLocalKey(CART)
      var cookies = document.cookie.split(";")
      if (cookies.length) {
        cookies.forEach((cookie) => {
          var c_name = cookie.split("=")[0].trim()
          if (c_name === "token") {
            this.deleteCookie(c_name)
          }
        })
        window.location.href = "/"
      }

    })

  }

  handleScroll = () => {
    const isSticky = window.scrollY > 5; // Example logic for stickiness
    this.setState({ isSticky });
  };

  componentDidMount() {
    this.checkUser()
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener('click', this.handleClickOutside);
    this.getHomeData();
  }


  async getHomeData() {

    const homeDataReq = await GET(HOME_DATA_URL);
    // const homeDataReq = await GET('https://azure-picxy-api.picxy.com/get_home_data/');

    this.setState({ featured_collections: homeDataReq?.data?.featured_collections });

    const collectionData = await GET(COLLECTIONS_URL);
    // const collectionData = await GET('https://azure-picxy-api.picxy.com/get_collections/');


    this.setState({ collections: collectionData.data.collections });
  }

  componentWillUnmount() {
    // Clean up event listener when component is unmounted
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener('click', this.handleClickOutside);
  }



  handleClickOutside = (e) => {
    // Check if the clicked element is outside of the dropdown menus
    const dropdown1 = document.getElementById('dropdown1');
    const dropdown2 = document.getElementById('dropdown2');

    if (dropdown1 && !dropdown1.contains(e.target)) {
      this.setState({ show_d1_menu: false });
    }
    if (dropdown2 && !dropdown2.contains(e.target)) {
      this.setState({ show_d2_menu: false });
    }
  };


  customAnimation = (photos, videos, anKey) => {
    const iconCart = <FontAwesomeIcon icon={faShoppingCart} className="p-i-nav" />

    let anim = <div>{iconCart}</div>, count = 0
    if (anKey > 0) {
      if (photos && photos.length) { count = count + photos.length }
      if (videos && videos.length) { count = count + videos.length }
      if (count) {
        anim = <Animated key={anKey} animationIn="pulse">{iconCart} <span className="cart-counter">{count}</span></Animated>
      }
    }
    else if (photos || videos) {
      if (photos) {
        count = count + photos.length
      }
      if (videos) {
        count = count + videos.length
      }
      if (count) {
        anim = <div>{iconCart} <span className="cart-counter">{count}</span></div>
      }
    }

    return anim

  }

  toggleDropDown = (e, d) => {
    if (d === 'd1') {
      this.setState({ show_d1_menu: !this.state.show_d1_menu })
    }
    else if (d === 'd2') {
      this.setState({ show_d2_menu: !this.state.show_d2_menu })
    }
  }

  // Toggle dropdown function for d1 and d2
  // toggleDropDown = (e, d) => {
  //   e.preventDefault();
  //   if (d === 'd1') {
  //     this.setState((prevState) => ({
  //       show_d1_menu: !prevState.show_d1_menu, // Toggle show_d1_menu
  //     }));
  //   } else if (d === 'd2') {
  //     this.setState((prevState) => ({
  //       show_d2_menu: !prevState.show_d2_menu, // Toggle show_d2_menu
  //     }));
  //   }
  // };


  render() {

    let { show_d1_menu, isSticky } = this.state

    const router = this.props.router

    let cart_counter = <CartContext.Consumer>
      {context => {
        let cart = context.cart
        let photos, videos
        let a
        if (cart) {
          photos = cart.photos
          videos = cart.videos || []
          a = this.customAnimation(photos, videos, context.anKey)
        }

        return a

      }}
    </CartContext.Consumer>


    let props = this.props

    let token = props.user || this.state.token

    let rightNav
    // let pricingItem = <li className="text-right">
    //   <PLink router={router} path={"/pricing"} className={"nav-link "} text={"Pricing"} />
    // </li>
    let pricingItem = <li className="text-right">
      <PLink router={router} path={"/pricing"} className={"nav-link pricing-tab"} text={"Pricing"} />

    </li>

    let display_username
    if (props.logged_in_user) {
      display_username = props.logged_in_user.first_name ? props.logged_in_user.first_name : props.logged_in_user.username.split('@')[0]
    }
    // Mobile Menu
    let mobileMenu
    if (token && props.logged_in_user) {
      mobileMenu = <div className="text-right d-block d-lg-none">
        {/* <div className="pt-3 pb-2">
          <PLink router={router} path={"/profile"} className={"nav-link "} text={`Hi, ${display_username}`} />
        </div> */}
        {/* <div className="py-2 me-2">
          <a className="btn btn-primary btn-sm" href="http://upload.picxy.com/" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faUpload} /> Upload
          </a>
        </div> */}
        <div className="py-2">
          <PLink router={router} path={"/photos"} className={"nav-link "} text={"Photos"} />
        </div>
        {/* <div className="py-2 ">
          <PLink router={router} path={"/videos"} className={"nav-link "} text={"VIDEOS"} />
        </div> */}

        {/* <div className="py-2 collection">
          <PLink router={router} path={"/collections"} className={"nav-link "} text={"Collections"} />
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53124 11.7812C9.39059 11.9218 9.19986 12.0008 9.00099 12.0008C8.80212 12.0008 8.61139 11.9218 8.47074 11.7812L4.22799 7.53849C4.15636 7.4693 4.09922 7.38655 4.05991 7.29504C4.02061 7.20354 3.99992 7.10513 3.99905 7.00554C3.99819 6.90596 4.01716 6.8072 4.05487 6.71502C4.09258 6.62285 4.14827 6.53911 4.21869 6.46869C4.28911 6.39827 4.37285 6.34258 4.46502 6.30487C4.5572 6.26716 4.65596 6.24819 4.75554 6.24905C4.85513 6.24992 4.95354 6.27061 5.04504 6.30991C5.13655 6.34922 5.2193 6.40636 5.28849 6.47799L9.00099 10.1905L12.7135 6.47799C12.8549 6.34137 13.0444 6.26578 13.241 6.26748C13.4377 6.26919 13.6258 6.34807 13.7649 6.48713C13.9039 6.62618 13.9828 6.81429 13.9845 7.01094C13.9862 7.20759 13.9106 7.39704 13.774 7.53849L9.53124 11.7812Z" fill="white" />
          </svg>

        </div> */}

        <NavDropdown feature_collection={this.state.featured_collections} collections={this.state.collections} />
        {/* <NavDropdown collections={this.state.collections} /> */}

        <div className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav">
          <PLink router={router} path={"/book_photographer"} className={"nav-link navpure-link "} text={"Book Photographer"} />
        </div>

        
        <PLink router={router} path={"/ai_face_sync"} className={"nav-link "} text={"AI-Face Sync"} />

        <div className="py-2">
          <PLink router={router} path={"/pricing"} className={"nav-link "} text={"Pricing"} />
        </div>


        {/* <div className="py-2">
          <PLink router={router} path={"https://calendly.com/safiq-m/30-minutes-meeting-safiq"} className={"nav-link"} text={"TALK TO OUR EXPERTS"} target="_blank" />
        </div> */}

        {/* <div className="py-2">
          <PLink router={router} path={"/cart"} className={"nav-link cart-icon"} text={cart_counter} />
        </div> */}

        <div className="py-2">
          <PLink router={router} path={"/likes"} className={"nav-link "} text={"Likes"} />
        </div>

        <div className="py-2">
          <PLink router={router} path={"/downloads"} className={"nav-link "} text={"Downloads"} />
        </div>

        <div className="py-2">
          <PLink router={router} path={"/orders"} className={"nav-link "} text={"Orders"} />
        </div>


        <div className="py-2">
          <PLink router={router} path={"/members"} className={"nav-link "} text={"Team Members"} />
        </div>

        <div className="py-2">
        <PLink router={router} path={"/facesync/event"} className={"dropdown-item"} text={"Open Events"} />
        </div>

        <div className="py-2">
          <a className="nav-link " href="/" onClick={this.logout}>
            Logout
          </a>
        </div>

      </div>
    }
    else {
      mobileMenu = <div className="text-right d-block d-lg-none">
        {/* <div className="py-2 pt-4 me-2">
          <a className="btn btn-primary btn-primary btn-sm" href="http://upload.picxy.com/" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faUpload} /> Upload
          </a>
        </div> */}
        <div className="py-2">
          <PLink router={router} path={"/photos"} className={"nav-link "} text={"Photos"} />
        </div>

        {/* <div className="py-2 ">
          <PLink router={router} path={"/videos"} className={"nav-link "} text={"Videos"} />
        </div> */}

        {/* <div className="py-2 collection">
          <PLink router={router} path={"/collections"} className={"nav-link "} text={"Collections"} />
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53124 11.7812C9.39059 11.9218 9.19986 12.0008 9.00099 12.0008C8.80212 12.0008 8.61139 11.9218 8.47074 11.7812L4.22799 7.53849C4.15636 7.4693 4.09922 7.38655 4.05991 7.29504C4.02061 7.20354 3.99992 7.10513 3.99905 7.00554C3.99819 6.90596 4.01716 6.8072 4.05487 6.71502C4.09258 6.62285 4.14827 6.53911 4.21869 6.46869C4.28911 6.39827 4.37285 6.34258 4.46502 6.30487C4.5572 6.26716 4.65596 6.24819 4.75554 6.24905C4.85513 6.24992 4.95354 6.27061 5.04504 6.30991C5.13655 6.34922 5.2193 6.40636 5.28849 6.47799L9.00099 10.1905L12.7135 6.47799C12.8549 6.34137 13.0444 6.26578 13.241 6.26748C13.4377 6.26919 13.6258 6.34807 13.7649 6.48713C13.9039 6.62618 13.9828 6.81429 13.9845 7.01094C13.9862 7.20759 13.9106 7.39704 13.774 7.53849L9.53124 11.7812Z" fill="white" />
          </svg>

        </div> */}

        <NavDropdown feature_collection={this.state.featured_collections} collections={this.state.collections} />
        {/* <NavDropdown collections={this.state.collections} /> */}

        <li className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav" style={{ cursor: 'pointer' }}>
          <PLink router={router} path={"/book_photographer"} className={"nav-link "} text={"Book Photographer"} />
        </li>

        <li className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav" style={{ cursor: 'pointer' }}>
          <PLink router={router} path={"/ai_face_sync"} className={"nav-link "} text={"AI-Face Sync"} />
        </li>

        

        <div className="py-2">
          <PLink router={router} path={"/pricing"} className={"nav-link "} text={"Pricing"} />
        </div>
        {/* <div className="py-2">
          <PLink router={router} path={"https://calendly.com/safiq-m/30-minutes-meeting-safiq"} className={"nav-link"} text={"TALK TO OUR EXPERTS"} target="_blank" />
        </div> */}

        {/* <div className="py-2">
          <PLink router={router} path={"/login"} className={"nav-link "} text={"Login"} />
        </div> */}
        <div className="py-2">
          <PLink router={router} path={"/login-customer"} className={"nav-link "} text={"Login"} />
        </div>

        {/* <div className="py-2">
          <PLink router={router} path={"/signup"} className={"nav-link "} text={"Signup"} />
        </div> */}
        <div className="py-2">
          <PLink router={router} path={"/signup-customer"} className={"nav-link "} text={"Signup"} />
        </div>

      </div>
    }

    // End of Mobile menu


    if (token) {
      let userName

      if (props.logged_in_user) {
        userName = <Link href="/profile"><a className="nav-link d-inline profile-name" title="Goto Your Profile">Hi, {display_username}</a></Link>

      }




      rightNav = (
        <div className='d-flex gap-3 align-items-cente mobi-100r'>
          {/* <div>
            <ul className='navbar-nav text-right me-2 text-lg-left d-none d-lg-flex'>
              {pricingItem}
            </ul>
          </div> */}
          <div id="dropdown1" className="icons profile-icon profile-dropdown position-relative">

            <PLink router={router} path={"/pricing"} className={"nav-link pricing-tab"} text={"Pricing"} />

            <PLink router={router} path={"/cart"} className={"nav-link cart-icon d-none d-lg-block"} text={cart_counter} />

            <div className='d-flex align-items-center'>
              <span className='me-2'>{userName}</span>
              {/* <img src="../static/img_new/user-icon.svg" alt="User" className="User-logo" /> */}
              <span className='user-logo-svg dropdown' onClick={(e) => this.toggleDropDown(e, 'd1')}>
                <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle opacity="1" cx="22.5" cy="22.5" r="22.5" fill="#2e3192" />
                  <path d="M31 31V29C31 27.9391 30.5786 26.9217 29.8284 26.1716C29.0783 25.4214 28.0609 25 27 25H19C17.9391 25 16.9217 25.4214 16.1716 26.1716C15.4214 26.9217 15 27.9391 15 29V31" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M23 21C25.2091 21 27 19.2091 27 17C27 14.7909 25.2091 13 23 13C20.7909 13 19 14.7909 19 17C19 19.2091 20.7909 21 23 21Z" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
            </div>

            {show_d1_menu && (<ul className="navbar-nav dropdown-menu">
              {/* <li className="nav-item my-auto me-lg-3 me-3">
          <a className="btn btn-primary btn-sm" href="http://upload.picxy.com/" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faUpload} />  Upload
          </a>
        </li> */}
              {/* {userName}

        <li className="nav-item me-4 me-lg-0">
          <PLink router={router} path={"/cart"} className={"nav-link "} text={cart_counter} />
        </li> */}
              {/* <li className="nav-item dropdown"> */}
              {/* <a className="nav-item nav-link  dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => { e.preventDefault(); this.toggleDropDown(e, 'd1') }}>

          <FontAwesomeIcon icon={faBars} className="p-i-nav" />
        </a> */}
              <div style={{ display: this.state.show_d1_menu ? 'block' : 'none' }}>
                <PLink router={router} path={"/likes"} className={"dropdown-item"} text={"Likes"} />

                <PLink router={router} path={"/downloads"} className={"dropdown-item"} text={"Downloads"} />

                <PLink router={router} path={"/orders"} className={"dropdown-item"} text={"Orders"} />


                <PLink router={router} path={"/members"} className={"dropdown-item"} text={"Team Members"} />

                <PLink router={router} path={"/facesync/event"} className={"dropdown-item"} text={"Open Events"} />

                <a className="dropdown-item" href="/" onClick={this.logout}>
                  Logout
                </a>
              </div>

              {/* </li> */}

            </ul>)}

          </div>
        </div>
      )

    }
    else {
      rightNav = (
        <div className='d-flex gap-3 align-items-center mobi-100'>
          {/* <div>
            <ul className='navbar-nav text-right me-2 text-lg-left d-none d-lg-flex'>
              {pricingItem}
            </ul>
          </div> */}
          <div id="dropdown1" className="icons profile-icon profile-dropdown dropdown">

            <PLink router={router} path={"/pricing"} className={"nav-link pricing-tab"} text={"Pricing"} />

            <span className='user-logo-svg dropdown' onClick={(e) => this.toggleDropDown(e, 'd1')}>
              <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="1" cx="22.5" cy="22.5" r="22.5" fill="#2e3192" />
                <path d="M31 31V29C31 27.9391 30.5786 26.9217 29.8284 26.1716C29.0783 25.4214 28.0609 25 27 25H19C17.9391 25 16.9217 25.4214 16.1716 26.1716C15.4214 26.9217 15 27.9391 15 29V31" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M23 21C25.2091 21 27 19.2091 27 17C27 14.7909 25.2091 13 23 13C20.7909 13 19 14.7909 19 17C19 19.2091 20.7909 21 23 21Z" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>


            {show_d1_menu && (<ul className="navbar-nav dropdown-menu">
              {/* <li>
                <PLink router={router} path={"/login"} className={"dropdown-item"} text={"Login"} />
              </li> */}
              <li>
                <PLink router={router} path={"/login-customer"} className={"dropdown-item"} text={"Login"} />
              </li>
              {/* <li>
                <PLink router={router} path={"/signup"} className={"dropdown-item"} text={"Signup"} />
              </li> */}
              <li>
                <PLink router={router} path={"/signup-customer"} className={"dropdown-item"} text={"Signup"} />
              </li>              
              {/* <li className="nav-item my-auto text-right me-2 me-lg-0 py-3 py-lg-0">
          <a className="btn btn-primary  btn-sm" href="http://upload.picxy.com/" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faUpload} />  Upload
          </a>
        </li> */}
            </ul>)}
          </div>
        </div>
      )
    }

    let mobSearchBar
    let mobSearchButton = <button type="button" onClick={(e) => { this.toggleMobileSearchBar(e) }}
      style={{ padding: 10 }} className={"navbar-toggler mobi-search "}>

      <FontAwesomeIcon icon={faSearch} />
    </button>
    if (this.state.show_mob_search_bar) {
      mobSearchBar = <div className="" style={{ top: 70, paddingTop: 10, paddingBottom: 20, backgroundColor: '#fff', zIndex: 1031, width: '100%' }}>
        <SearchComp search_term={this.props.search_term} />
      </div>
    }


    return <div style={{ position: 'relative' }}>
      <header className="sticky-header inner-nav">
        <nav className="navbar navbar-expand-lg navbar-light bg-none picxy-nav" style={{ backgroundColor: '#FFF' }} aria-label="navigation">
          <div className="container-fluid gap-xxl-2" >
            <PLink router={router} path={"/"} className={"main-logo"}
              // text={<Logo color="#000" />}
              text={<Logo color={isSticky ? "#FFF" : "#2E3192"} />}
            />

            <div className='d-flex gap-2 justify-content-between'>
              <div className='mobile-cart d-block d-lg-none'>
                <PLink router={router} path={"/cart"} className={"nav-link cart-icon"} text={cart_counter} />
              </div>

              {props.navSearchBtn === 'no' ? null : mobSearchButton}

              <button className="navbar-toggler" type="button" data-toggle="collapse"
                data-target="#picxynav" aria-controls="picxynav" aria-expanded="false"
                aria-label="Toggle navigation" onClick={(e) => { e.preventDefault(); this.toggleDropDown(e, 'd2') }}>
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>



            <div className="collapse navbar-collapse p-collapse justify-content-between" id="picxynav" style={{ display: this.state.show_d2_menu ? 'block' : 'none' }}>
              <ul className="navbar-nav text-right align-items-center me-2 text-lg-left d-none d-lg-flex">

                <li className="text-right me-4  me-lg-0">
                  <PLink router={router} path={"/photos"} className={"nav-link "} text={"Photos"} />
                </li>

                {/* <li className="text-right me-4  me-lg-0 ">
        <PLink router={router} path={"/videos"} className={"nav-link "} text={"VIDEOS"} />
        </li> */}

                {/* <li className="text-right me-4 me-lg-0 collection">
                  <PLink router={router} path={"/collections"} className={"nav-link "} text={"Collections"} />
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53124 11.7812C9.39059 11.9218 9.19986 12.0008 9.00099 12.0008C8.80212 12.0008 8.61139 11.9218 8.47074 11.7812L4.22799 7.53849C4.15636 7.4693 4.09922 7.38655 4.05991 7.29504C4.02061 7.20354 3.99992 7.10513 3.99905 7.00554C3.99819 6.90596 4.01716 6.8072 4.05487 6.71502C4.09258 6.62285 4.14827 6.53911 4.21869 6.46869C4.28911 6.39827 4.37285 6.34258 4.46502 6.30487C4.5572 6.26716 4.65596 6.24819 4.75554 6.24905C4.85513 6.24992 4.95354 6.27061 5.04504 6.30991C5.13655 6.34922 5.2193 6.40636 5.28849 6.47799L9.00099 10.1905L12.7135 6.47799C12.8549 6.34137 13.0444 6.26578 13.241 6.26748C13.4377 6.26919 13.6258 6.34807 13.7649 6.48713C13.9039 6.62618 13.9828 6.81429 13.9845 7.01094C13.9862 7.20759 13.9106 7.39704 13.774 7.53849L9.53124 11.7812Z" fill="white" />
                  </svg>

                </li> */}

                <NavDropdown feature_collection={this.state.featured_collections} collections={this.state.collections} />
                {/* <NavDropdown collections={this.state.collections} /> */}

                <li className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav" style={{ cursor: 'pointer' }}>
                  <PLink router={router} path={"/book_photographer"} className={"nav-link "} text={"Book Photographer"} />
                </li>

                <li className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav" style={{ cursor: 'pointer' }}>
                  <PLink router={router} path={"/ai_face_sync"} className={"nav-link "} text={"AI-Face Sync"} />
                </li>

                {/* {pricingItem} */}

                {/* <li className="text-right me-4 me-lg-0">
              <PLink router={router} path={"https://calendly.com/safiq-m/30-minutes-meeting-safiq"} className={"nav-link"} text={"TALK TO OUR EXPERTS"} target="_blank" />
            </li> */}

                <li className="nav-item d-none d-lg-flex align-items-center">
                  <SearchCompThin search_term={this.props.search_term} />
                </li>

              </ul>

              {rightNav}

              {mobileMenu}

            </div>
          </div>
        </nav>
        {mobSearchBar}
      </header>
    </div>
  }


}