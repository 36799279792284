import React from 'react'
import Link from 'next/link'
import PImage from './pImage'
import { setLocalKeyValue, getLocalKeyValue, getJsonData, setJsonData } from '../storage'
import Favorite from './favorite'
import VisibilitySensor from 'react-visibility-sensor'
import LazyLoad from 'react-lazyload';
import slugify from 'slugify'

export default class ListPhoto extends React.Component {

  render() {
    let photo = this.props.photo
    let cols = this.props.cols || 3
    let classname_requried = this.props.classname_requried ? false : true
    let content
    if (photo) {
      let like_btn = <Favorite liked={this.props.liked} photo={photo} reference={this.props.reference}></Favorite>
      content = <div className="photo-wrapper"><Link

        as={this.props.friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`} href={"/photo?id=" + photo.id}>

        <a href={this.props.friendly_url ? `/photo/${photo.id + '-' + slugify(photo.title)}` : `/photo/${photo.id}`}
          title={this.props.titlePrefix ? this.props.titlePrefix + photo.title : photo.title} >
          <div className="img-holder">

            <PImage key={photo.id} photo={photo} url={photo.thumbnail_medium} title={photo.title} />
            {like_btn}
          </div>
        </a>
      </Link>

      </div>
    }
    let className = classname_requried ? ((cols === 3) ? "col-sm-6 col-md-4 mb-4" : "col-sm-6 mb-4") : ""

    return <div className={className}>
      {content}
    </div>
  }
}
