import Img from 'react-image'
export default function PImage(props){

  let loader = <div className="image-holder">
  </div>
  let photo = props.photo
  if(photo && photo.thumbs){
    return  <picture >
                 <source srcSet={photo.thumbs.webp_700} type="image/webp" />
                 <source srcSet={photo.thumbnail_medium} type="image/jpeg" />
                 <img loading="lazy" key={photo.id} src={photo.thumbnail_medium}
                 className="img-fluid rounded-lg" alt={photo.title} />
               </picture>
  }
  else {
    // fallback
    return <img loading="lazy"  src={props.url} alt={props.title}
    className="img-fluid rounded-lg" />
  }


}
