import {TOKEN, ADD_TO_FAVOURITES_URL} from '../vars'
import {setLocalKeyValue, getLocalKeyValue, getJsonData, setJsonData, setSessionKeyValue} from '../storage'
import {POST} from '../helpers'
import {Animated} from "react-animated-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart as faHeartReg } from '@fortawesome/free-regular-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons'

export default class Favorite extends React.Component {

  constructor(props){
    super(props)
    this.state = {liked:this.props.liked, touched:false, submitting:false, clickAction:false, likes_count:this.props.photo.likes}
  }

  componentDidUpdate(prevProps) {
    if(prevProps.photo.id !== this.props.photo.id) {
      this.setState({liked:this.props.liked})
    }
  }


  likePhoto = (e) => {
    if(!this.state.submitting) {

      let token = getLocalKeyValue(TOKEN)
      if(token) {
        e.preventDefault()
        this.setState({touched:true, submitting:true, liked:!this.state.liked, clickAction:true})

        if(this.props.updateLikeCount) {
          this.props.updateLikeCount(!this.state.liked)
        }

        let photo_id = this.props.photo.id
        let data = {photo_id: photo_id, liked:!this.state.liked}

        POST(ADD_TO_FAVOURITES_URL, token, data).then((res)=>{
          let update_count = this.state.likes_count + (this.state.liked?1:-1)
          this.setState({submitting:false, clickAction:false, likes_count:update_count})
        }).catch((err)=>{
          this.setState({submitting:false, clickAction:false})
        })

        // Let animation complete

        // window.setTimeout(()=>{
        //     this.setState({clickAction:false})
        // }, 2000)
      }
      else {
        if(this.props.reference) {
            setSessionKeyValue('referer', this.props.reference)
        }
        window.location.href="/login-customer"
      }
    }

  }

  render() {
      let props = this.props
      let className = "picxy-fav-btn icon-drop-shadow"

      let holderClassName = "picxy-fav-holder"
      let animationClass = "heartBeat"
      let favIcon = <FontAwesomeIcon icon={faHeartReg} className={className} />
      let likes_text = <span>{this.state.likes_count>0?this.state.likes_count:''} {this.state.likes_count>1?"Likes":"Like"}</span>
      if (props.inLine) {
        holderClassName = "d-inline"
        animationClass = "d-inline"
        favIcon = <><img src="/static/img/details_photo/heart.svg" className="mr-1 img-fluid"/> {likes_text} </>
      }

      if(this.state.liked) {
         className += " liked"
         holderClassName += " liked"
         favIcon = <FontAwesomeIcon icon={faHeartSolid} className={className} />
         if(props.inLine) {
           favIcon = <><img src="/static/img/details_photo/heart_full.svg" className="mr-1 img-fluid"/> {likes_text}</>
         }
         // holderClassName = "picxy-fav-holder"
      }


      let lve = <span className={props.inLine?"":"picxy-fav-btn"}>
          {favIcon}
      </span>
      if(this.state.clickAction) {
        lve = <Animated key={this.props.photo.id} animationIn={animationClass}><span className="picxy-fav-btn">
            {favIcon}
        </span></Animated>
        if (props.inLine) {
          lve = <div className="spinner-border text-picxy" role="status">
                <span className="sr-only">Loading...</span>
              </div>
        }

      }

      // if this function exists
      // if(!this.state.submitting) {
      //   return <div className={holderClassName} onClick={this.likePhoto}>
      //         {lve}
      //   </div>
      // }

      return <div className={holderClassName} onClick={this.likePhoto}>
        {lve}
      </div>

  }

}
