import React from 'react'
import { throttle, debounce } from "throttle-debounce";
import slugify from 'slugify'
import { setLocalKeyValue, getLocalKeyValue, getJsonData, setJsonData } from '../storage'
import Router from 'next/router'
import VisibilitySensor from 'react-visibility-sensor'
import { RECENT_SEARCH_TERMS, CURRENT_SEARCH_TERM } from '../vars'
import CartContext from '../ctx/cartContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import SearchInput from './searchInput'
export function SearchComp(props) {



  return <CartContext.Consumer>{
    (ctxt) => {
      return <VisibilitySensor onChange={(v) => { ctxt.updateNavSearchVisibility(v) }} offset={{ top: 30 }}>
        <div className="d-flex justify-content-center no-gutters">
          <SearchBar {...props} />
        </div>
      </VisibilitySensor>
    }
  }
  </CartContext.Consumer>

}

export function SearchCompThin(props) {
  return <CartContext.Consumer>{
    (ctxt) => {
      return <div className={"bg-none " + (ctxt.show_nav_search_bar ? "" : "d-none")}>
        <div className="">
          <SearchBar {...props} />
        </div>
      </div>
    }
  }
  </CartContext.Consumer>
}

export default class SearchBar extends React.Component {
  is_unmounted = false
  constructor(props) {
    super(props);
    this.state = { q: props.search_term || '', isDropdownOpen: false, isNotHomePage: false, category: 'All', };
    this.search = this.search.bind(this);
    // Bind the method to this
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.changeQuery = this.changeQuery.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.autocompleteSearchThrottled = throttle(500, this.autocompleteSearch);
  }

  search(e) {
    let q_term = this.state.q
    e.preventDefault()
    if (q_term.length) {
      let recent_search_terms = getJsonData(RECENT_SEARCH_TERMS) || []
      if (!recent_search_terms.includes(this.state.q)) {
        recent_search_terms.push(this.state.q)
        setJsonData(RECENT_SEARCH_TERMS, recent_search_terms)
      }
      setLocalKeyValue(CURRENT_SEARCH_TERM, this.state.q)
      let query_term = slugify(this.state.q, '+');

      let selectedCategory = this.state.category.toLowerCase(); // Example: 'all', 'recent', 'popular'

      let searchUrl = `/search?q=${query_term}&order_by=${selectedCategory}`;

      Router.push(searchUrl, searchUrl);

      //Router.push('/search?q=' + query_term, '/search/' + query_term)

    }

  }

  componentDidMount() {
    let query_term = localStorage.getItem("current_search_term");
    this.setState({ q: query_term })
    Router.events.on('routeChangeStart', this.handleRouteChangeStart);
    Router.events.on('routeChangeComplete', this.handleRouteChangeComplete);

    // Check current path on mount
    this.handleRouteChangeComplete(window.location.pathname);

    $(document).ready(function () {


      $(".default_option").click(function () {
        $(".dropdown ul").addClass("active")
      });

      $(".dropdown ul li").click(function () {
        var text = $(this).text()
        $(".default_option").text(text);
        $(".dropdown ul").removeClass("active")
      })


    })

    this.handleScroll = () => {
      let query_term = localStorage.getItem("current_search_term")
      const header = document.querySelector('.sticky-header');
      const searchBox = document.querySelector('.wrapper .search_box');
      const searchBoxUl = document.querySelector('.wrapper .search_box .dropdown');
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      if (header) {
        if (scrollTop < 5) {
          header.classList.remove('scrolled');
          this.setState({ q: ''})
          // Hide the search box when scrolling down
          if (searchBox) {
            searchBox.style.display = 'flex';
            // searchBoxUl.style.display = 'flex';
            this.setState({ q: '' })
          }
        } else {
          header.classList.add('scrolled');
          // Show the search box when at the top of the page
          this.setState({ q: '' })
          if (searchBox) {
            searchBox.style.display = 'flex';
            this.setState({ q: '' })
            // searchBoxUl.style.display = 'none';
          }
        }
      }
    };
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    this.is_unmounted = true
    // Clean up the event listeners when component unmounts
    Router.events.off('routeChangeStart', this.handleRouteChangeStart);
    Router.events.off('routeChangeComplete', this.handleRouteChangeComplete);
    document.removeEventListener('scroll', this.handleScroll)
  }

  handleRouteChangeStart = () => {
    // Optionally, you can set a loading state here if needed
    console.log("Route change started...");
  };

  // Trigger when the route change is complete
  handleRouteChangeComplete = (url) => {
    console.log("Route change completed, URL:", url);

    // Update the state only after the route is fully loaded
    if (url !== '/') {
      this.setState({ isNotHomePage: true });
      this.setState({ q: '' })
    } else {
      this.setState({ isNotHomePage: false });
      this.setState({ q: '' })
    }
  };

  changeQuery = event => {
    this.setState({ q: event.target.value, search_term: slugify(event.target.value) }, () => {
      const q = this.state.q;
      if (q.length < 5) {
        this.autocompleteSearchThrottled(this.state.q);
      } else {
        this.autocompleteSearchDebounced(this.state.q);
      }
    });
  };

  // Method to toggle dropdown visibility
  toggleDropdown() {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen
    }));
  }

  autocompleteSearch = q => {
    this._fetch(q);
  };

  _fetch = q => {
    const _searches = this.state._searches || [];
    _searches.push(q);
    if (!this.is_unmounted) {
      this.setState({ _searches });
    }

  };

  handleCategoryChange = (category) => {
    this.setState({ category }); // Update category state when user selects a category
  }



  render() {
    const _searches = this.state._searches || [];
    const placeholder = this.props.placeholder || "Search Photos"
    return (
      <div className="sticky-top" >
        <form onSubmit={this.search} action="/search">

          {this.state.isNotHomePage ? (

            <div className="wrapper small">
              <div className="search_box">
                <div className="search_field">
                  <i className="fa fa-search search" onClick={this.search}></i>
                  <input
                    type="text"
                    className="input"
                    value={this.state.q}
                    name="q"
                    onChange={this.changeQuery}
                    placeholder="Find millions of premium-quality photos..."
                  />
                </div>
                {/* <div className="dropdown">
                  <div className="default_option">All Images</div>
                  <i className="fas fa-chevron-down"></i>
                  <ul>
                    <li
                      style={{ listStyleType: 'none' }}
                      onClick={() => this.handleCategoryChange('All')}
                      className={this.state.category === 'All' ? 'active' : ''}
                    >
                      All
                    </li>
                    <li
                      style={{ listStyleType: 'none' }}
                      onClick={() => this.handleCategoryChange('Recent')}
                      className={this.state.category === 'Recent' ? 'active' : ''}
                    >
                      Recent
                    </li>
                    <li
                      style={{ listStyleType: 'none' }}
                      onClick={() => this.handleCategoryChange('Popular')}
                      className={this.state.category === 'Popular' ? 'active' : ''}
                    >
                      Popular
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          ) : (
            <div className="wrapper">
              <div className="search_box">
                <div className="search_field">
                  <i className="fa fa-search search" onClick={this.search}></i>
                  <input
                    type="text"
                    className="input"
                    value={this.state.q}
                    name="q"
                    onChange={this.changeQuery}
                    placeholder="Find millions of premium-quality photos..."
                  />
                </div>
                {/* <div className="dropdown">
                  <div className="default_option">All Images</div>
                  <i className="fas fa-chevron-down"></i>
                  <ul>
                    <li
                      style={{ listStyleType: 'none' }}
                      onClick={() => this.handleCategoryChange('All')}
                      className={this.state.category === 'All' ? 'active' : ''}
                    >
                      All
                    </li>
                    <li
                      style={{ listStyleType: 'none' }}
                      onClick={() => this.handleCategoryChange('Recent')}
                      className={this.state.category === 'Recent' ? 'active' : ''}
                    >
                      Recent
                    </li>
                    <li
                      style={{ listStyleType: 'none' }}
                      onClick={() => this.handleCategoryChange('Popular')}
                      className={this.state.category === 'Popular' ? 'active' : ''}
                    >
                      Popular
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          )}

        </form>


      </div>
    );
  }
}



export class SearchBarVideo extends React.Component {
  is_unmounted = false
  constructor(props) {
    super(props);
    this.state = { q: props.search_term || '' };
    this.search = this.search.bind(this)
    this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    this.autocompleteSearchThrottled = throttle(500, this.autocompleteSearch);
  }

  search(e) {
    let q_term = this.state.q

    e.preventDefault()
    if (q_term.length) {
      let recent_search_terms = getJsonData(RECENT_SEARCH_TERMS) || []
      if (!recent_search_terms.includes(this.state.q)) {
        recent_search_terms.push(this.state.q)
        setJsonData(RECENT_SEARCH_TERMS, recent_search_terms)
      }
      setLocalKeyValue(CURRENT_SEARCH_TERM, this.state.q)
      let query_term = slugify(this.state.q, '+')

      Router.push('/videos?q=' + query_term, '/videos/' + query_term)
      this.setState({ q: '' })
    }

  }

  componentWillUnmount() {
    this.is_unmounted = true
    // Router.events.off('routeChangeStart', this.handleRouteChange);
  }

  changeQuery = event => {
    this.setState({ q: event.target.value, search_term: slugify(event.target.value) }, () => {
      const q = this.state.q;
      if (q.length < 5) {
        this.autocompleteSearchThrottled(this.state.q);
      } else {
        this.autocompleteSearchDebounced(this.state.q);
      }
    });
  };
  autocompleteSearch = q => {
    this._fetch(q);
  };

  _fetch = q => {
    const _searches = this.state._searches || [];
    _searches.push(q);
    if (!this.is_unmounted) {
      this.setState({ _searches });
    }

  };

  render() {
    const _searches = this.state._searches || [];
    const placeholder = this.props.placeholder || "Search Videos"
    return (
      <div className="container ">
        <form action="/videos" onSubmit={this.search}>
          <div className="input-group  picxy-search-input">

            <input
              placeholder={placeholder}
              type="text" className="form-control form-control-lg"
              value={this.state.q} name="q"
              onChange={this.changeQuery}
              aria-label="Search Photos"
            />

            <div className="input-group-append">
              <button className="btn  picxy-search-icon" style={{ borderLeftColor: 'transparent' }}
                type="submit" onClick={this.search}>
                <FontAwesomeIcon icon={faSearch} title="Search Videos" />

              </button>
            </div>
          </div>

        </form>


      </div>
    );
  }
}