import Logo from './logo'
import Link from 'next/link'
import CartContext from '../ctx/cartContext'
import { SearchCompThin, SearchComp } from './searchBar'
import { GET_CART_DETAIL_URL, TOKEN, CART, LOGOUT_URL, HOME_DATA_URL, COLLECTIONS_URL } from '../vars'
import { getLocalKeyValue, setLocalKeyValue, removeLocalKey } from '../storage'
import { Animated } from "react-animated-css";
import { POST, GET } from '../helpers'
import { PLink } from './nav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart, faUpload, faBars, faSearch } from '@fortawesome/free-solid-svg-icons';



export class NavDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDropdownOpen: false,
    };
  }

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isDropdownOpen: !prevState.isDropdownOpen,
    }));
  };

  render() {
    const { isDropdownOpen } = this.state;
    const { router, feature_collection, collections = [] } = this.props; // Ensure collections has a default empty array

    // Step 1: Get the first 6 unique items from feature_collection
    let accumulatedItems = feature_collection ? feature_collection.slice(0, 6) : [];

    // Step 2: Add unique items from collections that aren’t in feature_collection, if collections is defined
    const existingIds = new Set(accumulatedItems.map((item) => item.id));
    const uniqueCollectionItems = collections
      ? collections.filter((item) => !existingIds.has(item.id))
      : [];

    // Combine both arrays up to a max of 20 items
    accumulatedItems = accumulatedItems.concat(
      uniqueCollectionItems.slice(0, 20 - accumulatedItems.length)
    );

    return (
      <li
        className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav"
        onMouseEnter={() => this.toggleDropdown(true)}
        onMouseLeave={() => this.toggleDropdown(false)}
      >
        <a className="nav-link collection2" href="/collections">
          Collections
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.53124 11.7812C9.39059 11.9218 9.19986 12.0008 9.00099 12.0008C8.80212 12.0008 8.61139 11.9218 8.47074 11.7812L4.22799 7.53849C4.15636 7.4693 4.09922 7.38655 4.05991 7.29504C4.02061 7.20354 3.99992 7.10513 3.99905 7.00554C3.99819 6.90596 4.01716 6.8072 4.05487 6.71502C4.09258 6.62285 4.14827 6.53911 4.21869 6.46869C4.28911 6.39827 4.37285 6.34258 4.46502 6.30487C4.5572 6.26716 4.65596 6.24819 4.75554 6.24905C4.85513 6.24992 4.95354 6.27061 5.04504 6.30991C5.13655 6.34922 5.2193 6.40636 5.28849 6.47799L9.00099 10.1905L12.7135 6.47799C12.8549 6.34137 13.0444 6.26578 13.241 6.26748C13.4377 6.26919 13.6258 6.34807 13.7649 6.48713C13.9039 6.62618 13.9828 6.81429 13.9845 7.01094C13.9862 7.20759 13.9106 7.39704 13.774 7.53849L9.53124 11.7812Z"
              fill="white"
            />
          </svg>
        </a>

        {isDropdownOpen && (
          <div
            className="dropdown-menu custom-dropdown-menu show"
            style={{ width: '200px' }}
          >
            <div className="custom-grid">
              {/* Render the combined items, ensuring no duplicates */}
              {accumulatedItems.map((item) => (
                <PLink
                  key={item.id}
                  router={router}
                  path={`/collections/${item.slug}`}
                  className="dropdown-item"
                  text={item.name}
                />
              ))}
            </div>

            <hr className="my-2" />

            {/* Show More button at the end */}
            <button
              onClick={() => (window.location.href = '/collections')}
              className="dropdown-item show-more"
            >
              Show More
            </button>
          </div>
        )}
      </li>
    );
  }
}



export default class NavPure extends React.Component {


  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
    this.checkUser = this.checkUser.bind(this)
    this.state = { show_mob_search_bar: false, show_d1_menu: false, show_d2_menu: false, featured_collections: [], collections: [] }
  }

  toggleMobileSearchBar = (e) => {
    this.setState({ show_mob_search_bar: !this.state.show_mob_search_bar })
  }
  checkUser() {
    let token = getLocalKeyValue(TOKEN)
    if (token) {
      this.setState({ token: token })
    }
  }

  logout(e) {
    e.preventDefault();
    POST(LOGOUT_URL, getLocalKeyValue(TOKEN)).then((res) => {
    }).catch((err) => {

    })
    removeLocalKey(TOKEN)
    removeLocalKey(CART)
    document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    window.location.href = "/"

  }

  componentDidMount() {
    this.checkUser()
    window.addEventListener('click', this.handleClickOutside);

    this.getHomeData();

  }


  async getHomeData() {

    const homeDataReq = await GET(HOME_DATA_URL);

    this.setState({ featured_collections: homeDataReq?.data?.featured_collections })

    const collectionData = await GET(COLLECTIONS_URL);


    this.setState({ collections: collectionData.data.collections });
  }


  componentWillUnmount() {
    // Clean up the scroll event listener
    window.removeEventListener('click', this.handleClickOutside);

  }

  handleClickOutside = (e) => {
    // Check if the clicked element is outside of the dropdown menus
    const dropdown1 = document.getElementById('dropdown1');
    const dropdown2 = document.getElementById('dropdown2');

    if (dropdown1 && !dropdown1.contains(e.target)) {
      this.setState({ show_d1_menu: false });
    }
    if (dropdown2 && !dropdown2.contains(e.target)) {
      this.setState({ show_d2_menu: false });
    }
  };



  customAnimation = (photos, videos, anKey) => {
    const iconCart = <FontAwesomeIcon icon={faShoppingCart} className="p-i-nav" />

    let anim = <div>{iconCart}</div>, count = 0
    if (anKey > 0) {
      if (photos && photos.length) { count = count + photos.length }
      if (videos && videos.length) { count = count + videos.length }
      if (count) {
        anim = <Animated key={anKey} animationIn="pulse">{iconCart} <span className="cart-counter ">{count}</span></Animated>
      }
    }
    else if (photos || videos) {
      if (photos) {
        count = count + photos.length
      }
      if (videos) {
        count = count + videos.length
      }
      if (count) {
        anim = <div>{iconCart} <span className="cart-counter ">{count}</span></div>
      }
    }

    return anim

  }

  // toggleDropDown = (e, d) => {
  //   if (d === 'd1') {
  //     this.setState({ show_d1_menu: !this.state.show_d1_menu })
  //   }
  //   else if (d === 'd2') {
  //     this.setState({ show_d2_menu: !this.state.show_d2_menu })
  //   }
  // }

  // Toggle dropdown function for d1 and d2
  toggleDropDown = (e, d) => {
    e.preventDefault();
    if (d === 'd1') {
      this.setState((prevState) => ({
        show_d1_menu: !prevState.show_d1_menu, // Toggle show_d1_menu
      }));
    } else if (d === 'd2') {
      this.setState((prevState) => ({
        show_d2_menu: !prevState.show_d2_menu, // Toggle show_d2_menu
      }));
    }
  };

  render() {

    const router = this.props.router

    const { show_d1_menu } = this.state;
    // const {userName, cart_counter } = this.props; // Assuming props passed


    let cart_counter = <CartContext.Consumer>
      {context => {
        let cart = context.cart
        let photos, videos
        let a
        if (cart) {
          photos = cart.photos
          videos = cart.videos
          a = this.customAnimation(photos, videos, context.anKey)
        }

        return a

      }}
    </CartContext.Consumer>


    let props = this.props

    let token = props.user || this.state.token

    let rightNav
    let pricingItem = <li className="text-right">
      <PLink router={router} path={"/pricing"} className={"nav-link pricing-tab"} text={"Pricing"} />

    </li>


    let display_username
    if (props.logged_in_user) {
      display_username = props.logged_in_user.first_name ? props.logged_in_user.first_name : props.logged_in_user.username.split('@')[0]
    }

    // Mobile Menu
    let mobileMenu
    if (token) {
      mobileMenu = <div className="text-right d-block d-lg-none">
        {/* <div className="pt-3 pb-2">
          <PLink router={router} path={"/profile"} className={"nav-link "} text={`Hi, ${display_username}`} />
        </div> */}
        {/* <div className="py-2 me-2">
          <a className="btn btn-primary btn-primary btn-sm" href="http://upload.picxy.com/" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faUpload} /> Upload
          </a>
        </div> */}
        <div className="py-2">
          <PLink router={router} path={"/photos"} className={"nav-link "} text={"Photos"} />
        </div>


        {/* <div className="py-2 collection2">
          <PLink router={router} path={"/collections"} className={"nav-link "} text={"Collections"} />
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53124 11.7812C9.39059 11.9218 9.19986 12.0008 9.00099 12.0008C8.80212 12.0008 8.61139 11.9218 8.47074 11.7812L4.22799 7.53849C4.15636 7.4693 4.09922 7.38655 4.05991 7.29504C4.02061 7.20354 3.99992 7.10513 3.99905 7.00554C3.99819 6.90596 4.01716 6.8072 4.05487 6.71502C4.09258 6.62285 4.14827 6.53911 4.21869 6.46869C4.28911 6.39827 4.37285 6.34258 4.46502 6.30487C4.5572 6.26716 4.65596 6.24819 4.75554 6.24905C4.85513 6.24992 4.95354 6.27061 5.04504 6.30991C5.13655 6.34922 5.2193 6.40636 5.28849 6.47799L9.00099 10.1905L12.7135 6.47799C12.8549 6.34137 13.0444 6.26578 13.241 6.26748C13.4377 6.26919 13.6258 6.34807 13.7649 6.48713C13.9039 6.62618 13.9828 6.81429 13.9845 7.01094C13.9862 7.20759 13.9106 7.39704 13.774 7.53849L9.53124 11.7812Z" fill="white" />
          </svg>

        </div> */}

        {/* <NavDropdown feature_collection={this.props.feature_collection} /> */}

        <NavDropdown feature_collection={this.state.featured_collections} collections={this.state.collections} />
        <div className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav">
          <PLink router={router} path={"/book_photographer"} className={"nav-link navpure-link "} text={"Book Photographer"} />
        </div>

        {/* <div className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav">
          <PLink router={router} path={"/ai_face_sync"} className={"nav-link navpure-link "} text={"AI-Face Sync"} />
        </div> */}

       

        {/* <div className="py-2">
          <PLink router={router} path={"/pricing"} className={"nav-link "} text={"Pricing"} />
        </div> */}

        {/* <div className="py-2">
          <PLink router={router} path={"https://calendly.com/safiq-m/30-minutes-meeting-safiq"} className={"dropdown-item"} text={"TALK TO OUR EXPERTS"} target="_blank" />
        </div> */}

        {/* <div className="py-2">
          <PLink router={router} path={"/cart"} className={"nav-link cart-icon"} text={cart_counter} />
        </div> */}

        <div className="py-2">
          <PLink router={router} path={"/likes"} className={"nav-link "} text={"Likes"} />
        </div>

        <div className="py-2">
          <PLink router={router} path={"/downloads"} className={"nav-link "} text={"Downloads"} />
        </div>

        <div className="py-2">
          <PLink router={router} path={"/orders"} className={"nav-link "} text={"Orders"} />
        </div>


        <div className="py-2">
          <PLink router={router} path={"/members"} className={"nav-link "} text={"Team Members"} />
        </div>

        <div className="py-2">
          <PLink router={router} path={"/facesync/event"} className={"dropdown-item"} text={"Open Events"} />
        </div>

        <div className="py-2">
          <a className="nav-link " href="/" onClick={this.logout}>
            Logout
          </a>
        </div>

      </div>
    }
    else {
      mobileMenu = <div className="text-right d-block d-lg-none">
        {/* <div className="py-2 pt-4 me-2">
          <a className="btn btn-primary btn-primary btn-sm" href="http://upload.picxy.com/" target="_blank" rel="noopener">
            <FontAwesomeIcon icon={faUpload} />  Upload
          </a>
        </div> */}
        <div className="py-2">
          <PLink router={router} path={"/photos"} className={"nav-link "} text={"Photos"} />
        </div>

        {/* <div className="py-2 ">
          <PLink router={router} path={"/videos"} className={"nav-link "} text={"Videos"} />
        </div> */}

        {/* <div className="py-2 collection2">
          <PLink router={router} path={"/collections"} className={"nav-link "} text={"Collections"} />
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.53124 11.7812C9.39059 11.9218 9.19986 12.0008 9.00099 12.0008C8.80212 12.0008 8.61139 11.9218 8.47074 11.7812L4.22799 7.53849C4.15636 7.4693 4.09922 7.38655 4.05991 7.29504C4.02061 7.20354 3.99992 7.10513 3.99905 7.00554C3.99819 6.90596 4.01716 6.8072 4.05487 6.71502C4.09258 6.62285 4.14827 6.53911 4.21869 6.46869C4.28911 6.39827 4.37285 6.34258 4.46502 6.30487C4.5572 6.26716 4.65596 6.24819 4.75554 6.24905C4.85513 6.24992 4.95354 6.27061 5.04504 6.30991C5.13655 6.34922 5.2193 6.40636 5.28849 6.47799L9.00099 10.1905L12.7135 6.47799C12.8549 6.34137 13.0444 6.26578 13.241 6.26748C13.4377 6.26919 13.6258 6.34807 13.7649 6.48713C13.9039 6.62618 13.9828 6.81429 13.9845 7.01094C13.9862 7.20759 13.9106 7.39704 13.774 7.53849L9.53124 11.7812Z" fill="white" />
          </svg>

        </div> */}

        {/* <NavDropdown feature_collection={this.props.feature_collection} /> */}

        <NavDropdown feature_collection={this.state.featured_collections} collections={this.state.collections} />
        <div className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav">
          <PLink router={router} path={"/book_photographer"} className={"nav-link navpure-link "} text={"Book Photographer"} />
        </div>

        <div className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav">
          <PLink router={router} path={"/ai_face_sync"} className={"nav-link navpure-link "} text={"AI-Face Sync"} />
        </div>       

        {/* 
        <div className="py-2">
          <PLink router={router} path={"/pricing"} className={"nav-link "} text={"Pricing"} />
        </div> */}

        {/* <div className="py-2">
          <PLink router={router} path={"https://calendly.com/safiq-m/30-minutes-meeting-safiq"} className={"dropdown-item"} text={"TALK TO OUR EXPERTS"} target="_blank" />
        </div> */}

        {/* <div className="py-2">
          <PLink router={router} path={"/login"} className={"nav-link "} text={"Login"} />
        </div> */}
        <div className="py-2">
          <PLink router={router} path={"/login-customer"} className={"nav-link "} text={"Login"} />
        </div>
        {/* <div className="py-2">
          <PLink router={router} path={"/signup"} className={"nav-link "} text={"Signup"} />
        </div> */}
        <div className="py-2">
          <PLink router={router} path={"/signup-customer"} className={"nav-link "} text={"Signup"} />
        </div>

      </div>
    }

    // End of Mobile menu

    if (token) {
      let userName
      if (props.logged_in_user) {
        userName = <span className="navbar-text navpure-link" ><Link href="/profile"><a className="nav-link navpure-link d-inline profile-name" title="Goto Your Profile">Hi, {display_username}</a></Link></span>
      }

      // rightNav = <ul className="navbar-nav navbar-right ml-auto mt-2 mt-lg-0 text-right d-none d-lg-flex">
      //   <li className="nav-item my-auto me-lg-3 me-3">
      //     <a className="btn btn-primary btn-primary btn-sm" href="https://upload.picxy.com/" target="_blank" rel="noopener">
      //       <FontAwesomeIcon icon={faUpload} /> Upload
      //     </a>
      //   </li>
      //   {userName}

      //   <li className="nav-item me-4 me-lg-0">
      //     <PLink router={router} path={"/cart"} className={"nav-link "} text={cart_counter} />
      //   </li>
      //   <li className="nav-item dropdown">
      //     <a className="nav-item nav-link  dropdown-toggle" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e) => { e.preventDefault(); this.toggleDropDown(e, 'd1') }}>
      //       <FontAwesomeIcon icon={faBars} className="p-i-nav" />
      //     </a>
      //     <div className="dropdown-menu dropdown-menu-right text-right text-lg-left" style={{ display: this.state.show_d1_menu ? 'block' : 'none' }}>

      //       <PLink router={router} path={"/likes"} className={"dropdown-item"} text={"Likes"} />

      //       <PLink router={router} path={"/downloads"} className={"dropdown-item"} text={"Downloads"} />

      //       <PLink router={router} path={"/orders"} className={"dropdown-item"} text={"Orders"} />

      //       <PLink router={router} path={"/members"} className={"dropdown-item"} text={"Team Members"} />

      //       <a className="dropdown-item" href="/" onClick={this.logout}>
      //         Logout
      //       </a>
      //     </div>

      //   </li>

      // </ul>

      rightNav = (
        <>

          {/* <div>icons profile-icon profile-dropdown dropdown</div> */}
          <div id="dropdown1" className='icons profile-icon profile-dropdown position-relative'>

            <PLink router={router} path={"/pricing"} className={"nav-link pricing-tab"} text={"Pricing"} />

            <PLink router={router} path={"/cart"} className={"nav-link cart-icon d-none d-lg-block"} text={cart_counter} />
            <div className='d-flex align-items-center'>
              <span className='me-2'>{userName}</span>
              <span className="dropdown" onClick={(e) => this.toggleDropDown(e, 'd1')}>
                <img src="../static/img_new/user-icon.svg" alt="User" className="User-logo" />
              </span>
            </div>

            {show_d1_menu && (
              <ul className="navbar-nav dropdown-menu">
                {/* <li className="nav-item my-auto me-lg-3 me-3">
                <a className="btn btn-primary btn-primary btn-sm" href="https://upload.picxy.com/" target="_blank" rel="noopener">
                  <FontAwesomeIcon icon={faUpload} /> Upload
                </a>
              </li> */}


                <li>
                  <PLink router={router} path={"/likes"} className={"dropdown-item"} text={"Likes"} />
                </li>
                <li>
                  <PLink router={router} path={"/downloads"} className={"dropdown-item"} text={"Downloads"} />
                </li>
                <li>
                  <PLink router={router} path={"/orders"} className={"dropdown-item"} text={"Orders"} />
                </li>
                <li>
                  <PLink router={router} path={"/members"} className={"dropdown-item"} text={"Team Members"} />
                </li>
                <li>
                <PLink router={router} path={"/facesync/event"} className={"dropdown-item"} text={"Open Events"} />
                </li>
                <li>
                  <a className="dropdown-item" href="/" onClick={this.logout}>
                    Logout
                  </a>
                </li>
              </ul>
            )}
          </div>

          {/* Conditionally render the dropdown based on show_d1_menu */}

        </>
      );


    }
    else {
      rightNav =

        (

          <div className='d-flex gap-3 align-items-center mobi-100'>
            {/* <div>
              <ul className='navbar-nav text-right me-2 text-lg-left d-none d-lg-flex align-items-center'>
                {pricingItem}
              </ul>
            </div> */}
            <div id="dropdown1" className="icons profile-icon profile-dropdown dropdown">

              <PLink router={router} path={"/pricing"} className={"nav-link pricing-tab"} text={"Pricing"} />

              {/* <img src="../static/img_new/user-icon.svg" alt="User" className="User-logo" /> */}

              <span className="dropdown" onClick={(e) => this.toggleDropDown(e, 'd1')}>
                <img src="../static/img_new/user-icon.svg" alt="User" className="User-logo" />
              </span>


              {show_d1_menu && (<ul className="navbar-nav dropdown-menu">



                {/* <li>
                  <PLink router={router} path={"/login"} className={"dropdown-item"} text={"Login"} />

                </li> */}
                <li>
                  <PLink router={router} path={"/login-customer"} className={"dropdown-item"} text={"Login"} />
                </li>
                {/* <li>
                  <PLink router={router} path={"/signup"} className={"dropdown-item"} text={"Signup"} />
                </li> */}
                <li>
                  <PLink router={router} path={"/signup-customer"} className={"dropdown-item"} text={"Signup"} />
                </li>
                {/* <li className="nav-item my-auto text-right me-2 me-lg-0 py-3 py-lg-0">
                <a className="btn btn-primary btn-primary btn-sm" href="https://upload.picxy.com/" target="_blank" rel="noopener">
                  <FontAwesomeIcon icon={faUpload} /> Upload
                </a>
              </li> */}
              </ul>)}
            </div>
          </div>

        )
    }

    let mobSearchBar
    let mobSearchButton = <button type="button" onClick={(e) => { this.toggleMobileSearchBar(e) }}
      style={{ position: 'absolute', right: 100, top: 17, padding: 10 }} className={"navbar-toggler"}>
      <FontAwesomeIcon icon={faSearch} />
    </button>
    if (this.state.show_mob_search_bar) {
      mobSearchBar = <div className="" style={{ top: 70, paddingTop: 10, paddingBottom: 20, backgroundColor: '#fff', zIndex: 1031, width: '100%' }}>
        <SearchComp search_term={this.props.search_term} />
      </div>
    }


    return <div style={{ position: 'relative' }}>
      <header className="sticky-header">
        <nav className="navbar navbar-expand-lg bg-none picxy-nav pure-nav"
          style={{ backgroundColor: 'transparent' }}>
          <div className="container-fluid gap-xxl-4 gap-md-4">

            <PLink router={router} path={"/"} className={"main-logo"} text={<Logo color="#fff" />} />

            <div className='d-flex gap-2 justify-content-between'>
              <div className='mobile-cart d-block d-lg-none'>
                <PLink router={router} path={"/cart"} className={"nav-link cart-icon"} text={cart_counter} />
              </div>

              <button className="navbar-toggler picxy-toggler navpure-link" type="button" data-toggle="collapse"
                data-target="#picxynav" aria-controls="picxynav" aria-expanded="false" aria-label="Toggle navigation" onClick={(e) => { e.preventDefault(); this.toggleDropDown(e, 'd2') }}>
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            <div className="collapse navbar-collapse p-collapse justify-content-between" id="picxynav" style={{ display: this.state.show_d2_menu ? 'block' : 'none' }}>
              <ul className="navbar-nav text-right align-items-center me-2 text-lg-left d-none d-lg-flex align-items-center">

                <li className="text-right me-4  me-lg-0">
                  <PLink router={router} path={"/photos"} className={"nav-link  navpure-link"} text={"Photos"} />
                </li>

                {/* <li className="text-right me-4  me-lg-0">
        <PLink router={router} path={"/shipping"} className={"nav-link  navpure-link"} text={"SHIPPING"} />
        </li> */}

                {/* <li className="text-right me-4 me-lg-0 ">
        <PLink router={router} path={"/videos"} className={"nav-link  navpure-link"} text={"VIDEOS"} />
        </li> */}

                {/* <li className="text-right me-4 me-lg-0">
                  <PLink router={router} path={"/collections"} className={"nav-link  navpure-link"} text={"COLLECTIONS"} />
                </li> */}

                {/* <NavDropdown feature_collection={this.props.feature_collection} /> */}
                <NavDropdown feature_collection={this.state.featured_collections} collections={this.state.collections} />

                <li className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav" style={{ cursor: 'pointer' }}>
                  <PLink router={router} path={"/book_photographer"} className={"nav-link navpure-link "} text={"Book Photographer"} />
                </li>

                <li className="py-2 text-right me-4 me-lg-0 nav-item li-inner-nav" style={{ cursor: 'pointer' }}>
                  <PLink router={router} path={"/ai_face_sync"} className={"nav-link navpure-link "} text={"AI-Face Sync"} />
                </li>

                {/* {pricingItem} */}

                {/* <li className="text-right me-4 me-lg-0">
                  <PLink router={router} path={"https://calendly.com/safiq-m/30-minutes-meeting-safiq"} className={"nav-link  navpure-link"} text={"TALK TO OUR EXPERTS"} target="_blank" />
                </li> */}




                <li className="nav-item d-none d-lg-block">
                  <SearchCompThin search_term={this.props.search_term} />
                </li>

              </ul>

              {rightNav}

              {mobileMenu}

            </div>
          </div>
        </nav>
      </header>
      {mobSearchBar}
    </div>
  }


}