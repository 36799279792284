import Link from 'next/link'
import slugify from 'slugify'
import Router from 'next/router';
import Head from 'next/head'

export default function Pagination(props) {

  let next_page_link
  let previous_page_link

  if (props.next_page) {

    next_page_link = <Link as={props.as_next_page} href={props.next_page} >
      <a href={props.as_next_page}>Next</a>
    </Link>
  }
  if (props.previous_page) {
    previous_page_link = <Link href={props.previous_page}>
      <a href={props.previous_page}>Next</a>
    </Link>

  }

  return <div className="my-3">
    {previous_page_link}
    {next_page_link}
  </div>

}


export function SimplePagerSelfCanon(props) {
  let next_page_link
  let previous_page_link
  let total_pages
  let current_page



  let pagination = props.pagination



  const currentPage = parseInt(pagination.page)

  let nextActualPath = "?page=" + (currentPage + 1)
  let previousActualPath = "?page=" + (currentPage - 1)
  let currentActualPath = "?page=" + currentPage

  if (props.search_term) {
    nextActualPath = "?search=" + slugify(props.search_term, '+') + "&page=" + (currentPage + 1)
    previousActualPath = "?search=" + slugify(props.search_term, '+') + "&page=" + (currentPage - 1)
  }


  let canonical, nextRel, prevRel, canons

  if (props.root_page) {


    if (pagination.total_pages > currentPage) {
      nextRel = <link rel="next" href={props.root_page + nextActualPath} />
    }

    if (currentPage === 1) {
      canonical = <link rel="canonical" href={props.root_page} />
    }
    else {
      canonical = <link rel="canonical" href={props.root_page + currentActualPath} />
    }

    if (currentPage === 2) {
      prevRel = <link rel="prev" href={props.root_page} />
    }
    else if (currentPage > 2) {
      prevRel = <link rel="prev" href={props.root_page + previousActualPath} />
    }
    canons = <Head>
      {prevRel}{nextRel}{canonical}
    </Head>

  }

  if (pagination.total_pages > currentPage) {
    next_page_link = <div className="list-inline-item">
      <Link href={nextActualPath} >
        <a className="btn btn-sm btn-primary">Next</a>
      </Link>
    </div>
  }
  else {
    next_page_link = <div className="list-inline-item">
    </div>
  }

  if (currentPage > 1) {
    previous_page_link = <div className="list-inline-item">
      <Link href={previousActualPath}>
        <a className="btn btn-sm btn-primary">Previous</a>
      </Link>
    </div>
  }
  else {
    previous_page_link = <div className="list-inline-item">
    </div>
  }

  return <nav>
    <div className="pull-left my-3">
      {previous_page_link}
    </div>
    <div className="pull-right my-3">
      {next_page_link}
    </div>
    {canons}
  </nav>

}

export function SimplePager(props) {

  let next_page_link
  let previous_page_link
  let total_pages
  let current_page



  let pagination = props.pagination


  const currentPage = parseInt(pagination.page)

  let nextActualPath = "?page=" + (currentPage + 1)
  let previousActualPath = "?page=" + (currentPage - 1)

  if (props.search_term) {
    nextActualPath = "?search=" + slugify(props.search_term, '+') + "&page=" + (currentPage + 1)
    previousActualPath = "?search=" + slugify(props.search_term, '+') + "&page=" + (currentPage - 1)
  }


  let canonical, nextRel, prevRel, canons

  if (props.root_page) {
    canonical = <link rel="canonical" href={props.root_page} />

    if (pagination.total_pages > currentPage) {
      nextRel = <link rel="next" href={props.root_page + nextActualPath} />
    }

    if (currentPage === 2) {
      prevRel = <link rel="prev" href={props.root_page} />
    }
    else if (currentPage > 2) {
      prevRel = <link rel="prev" href={props.root_page + previousActualPath} />
    }
    canons = <Head>
      {prevRel}{nextRel}{canonical}
    </Head>

  }

  if (pagination.total_pages > currentPage) {
    next_page_link = <div className="list-inline-item">
      <Link href={nextActualPath} >
        <a className="btn btn-sm btn-primary">Next</a>
      </Link>
    </div>
  }
  else {
    next_page_link = <div className="list-inline-item">
    </div>
  }

  if (currentPage > 1) {
    previous_page_link = <div className="list-inline-item">
      <Link href={previousActualPath}>
        <a className="btn btn-sm btn-primary">Previous</a>
      </Link>
    </div>
  }
  else {
    previous_page_link = <div className="list-inline-item">
    </div>
  }

  return <nav>
    <div className="pull-left my-3">
      {previous_page_link}
    </div>
    <div className="pull-right my-3">
      {next_page_link}
    </div>
    {canons}
  </nav>

}


function generalPageNumberSubmit(e, totalPages) {
  e.preventDefault()
  let requirePageNumber = e.target.page.value;

  let current_page = parseInt(requirePageNumber) || 0
  let total_Pages = parseInt(totalPages) || 0

  if (!isNaN(requirePageNumber) && current_page <= total_Pages) {
    let p = "/photos" + '?page=' + requirePageNumber
    Router.push(p)
  }
}


export function GeneralPagination(props) {

  let next_page_link
  let previous_page_link
  let total_pages
  let current_page


  const nextActualPath = "?page=" + props.next_page
  const previousActualPath = "?page=" + props.previous_page

  if (props.next_page) {
    next_page_link = <li className="list-inline-item">
      <Link href={nextActualPath} >
        <a className="btn btn-sm btn-primary">Next</a>
      </Link>
    </li>
  }
  else {
    next_page_link = <li className="list-inline-item">

    </li>
  }

  if (props.previous_page) {
    previous_page_link = <li className="list-inline-item">
      <Link href={previousActualPath}>
        <a className="btn btn-sm btn-primary">Previous</a>
      </Link>
    </li>
  }
  else {
    previous_page_link = <li className="list-inline-item">

    </li>
  }

  let pageNumberForm = <form key={props.current_page} className="form-page-num" onSubmit={(e) => { generalPageNumberSubmit(e, props.total_pages) }}>
    <input name="page" type="text" className="pagi-n-input" defaultValue={props.current_page} aria-label="enter page number" /></form>

  if (props.current_page) {
    current_page = <li className="list-inline-item active" aria-current="page">
      <span className="">
        {pageNumberForm} of {props.total_pages}
      </span>
    </li>
  }

  return <nav>
    <ul className="list-inline my-3">
      {previous_page_link}
      {current_page}
      {next_page_link}
    </ul>
  </nav>


}

function userPageNumberSubmit(e, username, totalPages) {
  e.preventDefault()
  let requirePageNumber = e.target.page.value;

  let current_page = parseInt(requirePageNumber) || 0
  let total_Pages = parseInt(totalPages) || 0

  if (!isNaN(requirePageNumber) && current_page <= total_Pages) {
    let p = "/" + username + '?page=' + requirePageNumber
    let aP = "/?username=" + username + '&page=' + requirePageNumber

    Router.push(aP, p)
  }
}
function userVideosPageNumberSubmit(e, username, totalPages) {
  e.preventDefault()
  let requirePageNumber = e.target.page.value;

  let current_page = parseInt(requirePageNumber) || 0
  let total_Pages = parseInt(totalPages) || 0

  if (!isNaN(requirePageNumber) && current_page <= total_Pages) {
    let p = "/" + username + '/videos?page=' + requirePageNumber
    let aP = "/?type=videos&username=" + username + '&page=' + requirePageNumber

    Router.push(aP, p)
  }
}

function eventsPageNumberSubmit(e, event_name, totalPages) {
  e.preventDefault()
  let requirePageNumber = e.target.page.value;

  let current_page = parseInt(requirePageNumber) || 0
  let total_Pages = parseInt(totalPages) || 0

  if (!isNaN(requirePageNumber) && current_page <= total_Pages) {
    let p = "/events/" + event_name + '?page=' + requirePageNumber
    let aP = "/events/event?event_name=" + event_name + '&page=' + requirePageNumber

    Router.push(aP, p)
  }
}


export function UserVideosPagination(props) {

  let previous_page = props.current_page > 1
  let next_page = props.total_pages > props.current_page
  let current_page = props.current_page
  let total_pages = props.total_pages
  let nKey = new Date()
  // for display
  let nextAsPath = '/' + props.username + '/videos?page=' + (current_page + 1)
  let previousAsPath = '/' + props.username + '/videos?page=' + (current_page - 1)

  // for actual path
  let nextActualPath = '/?type=videos&username=' + props.username + '&page=' + (current_page + 1)
  let previousActualPath = '/?type=videos&username=' + props.username + '&page=' + (current_page - 1)



  if (next_page) {
    next_page = <li className="list-inline-item">
      <Link href={nextActualPath} as={nextAsPath}>
        <a className="btn btn-sm btn-primary" href={nextAsPath}>Next</a>
      </Link>
    </li>
  }
  else {
    next_page = <li className="list-inline-item">
      <button className="btn btn-sm btn-primary" disabled>Next</button>
    </li>
  }

  if (previous_page) {
    previous_page = <li className="list-inline-item">
      <Link href={previousActualPath} as={previousAsPath}>
        <a className="btn btn-sm btn-primary" href={previousAsPath}>Previous</a>
      </Link>
    </li>
  }
  else {
    previous_page = <li className="list-inline-item">
      <button className="btn btn-sm btn-primary" disabled>Previous</button>
    </li>
  }


  let pageNumberForm = <form key={current_page} className="form-page-num" onSubmit={(e) => { userVideosPageNumberSubmit(e, props.username, total_pages) }}>
    <input name="page" type="text" className="pagi-n-input" defaultValue={current_page} aria-label="enter page number" /></form>

  if (current_page) {
    current_page = <li className="list-inline-item active" aria-current="page">
      <span className="">
        {pageNumberForm} of {total_pages}
        <span className="sr-only">(current)</span>
      </span>
    </li>
  }

  return <nav key={nKey}>
    <ul className="list-inline my-3">
      {previous_page}
      {current_page}
      {next_page}
    </ul>
  </nav>

}

export function UserPhotosPagination(props) {

  let previous_page = props.current_page > 1
  let next_page = props.total_pages > props.current_page
  let current_page = props.current_page
  let total_pages = props.total_pages
  let nKey = new Date()
  // for display
  let nextAsPath = '/' + props.username + '?page=' + (current_page + 1)
  let previousAsPath = '/' + props.username + '?page=' + (current_page - 1)

  // for actual path
  let nextActualPath = '/?username=' + props.username + '&page=' + (current_page + 1)
  let previousActualPath = '/?username=' + props.username + '&page=' + (current_page - 1)



  if (next_page) {
    next_page = <li className="list-inline-item">
      <Link href={nextActualPath} as={nextAsPath}>
        <a className="btn btn-sm btn-primary" href={nextAsPath}>Next</a>
      </Link>
    </li>
  }
  else {
    next_page = <li className="list-inline-item">
      <button className="btn btn-sm btn-primary" disabled>Next</button>
    </li>
  }

  if (previous_page) {
    previous_page = <li className="list-inline-item">
      <Link href={previousActualPath} as={previousAsPath}>
        <a className="btn btn-sm btn-primary" href={previousAsPath}>Previous</a>
      </Link>
    </li>
  }
  else {
    previous_page = <li className="list-inline-item">
      <button className="btn btn-sm btn-primary" disabled>Previous</button>
    </li>
  }


  let pageNumberForm = <form key={current_page} className="form-page-num" onSubmit={(e) => { userPageNumberSubmit(e, props.username, total_pages) }}>
    <input name="page" type="text" className="pagi-n-input" defaultValue={current_page} aria-label="enter page number" /></form>

  if (current_page) {
    current_page = <li className="list-inline-item active" aria-current="page">
      <span className="">
        {pageNumberForm} of {total_pages}
        <span className="sr-only">(current)</span>
      </span>
    </li>
  }

  return <nav key={nKey}>
    <ul className="list-inline my-3">
      {previous_page}
      {current_page}
      {next_page}
    </ul>
  </nav>

}

export function EventPhotosPagination(props) {

  let previous_page = props.previous_page
  let next_page = props.next_page
  let current_page = props.current_page
  let total_pages = props.total_pages
  let nKey = new Date()
  // for display
  let nextAsPath = '/events/' + props.event_name + '?page=' + next_page
  let previousAsPath = '/events/' + props.event_name + '?page=' + previous_page

  // for actual path
  let nextActualPath = '/events/event?event_name=' + props.event_name + '&page=' + next_page
  let previousActualPath = '/events/event?event_name=' + props.event_name + '&page=' + previous_page



  if (next_page) {
    next_page = <li className="list-inline-item">
      <Link href={nextActualPath} as={nextAsPath}>
        <a className="btn btn-sm btn-primary" href={nextAsPath}>Next</a>
      </Link>
    </li>
  }
  else {
    next_page = <li className="list-inline-item">
      <button className="btn btn-sm btn-primary" disabled>Next</button>
    </li>
  }

  if (previous_page) {
    previous_page = <li className="list-inline-item">
      <Link href={previousActualPath} as={previousAsPath}>
        <a className="btn btn-sm btn-primary" href={previousAsPath}>Previous</a>
      </Link>
    </li>
  }
  else {
    previous_page = <li className="list-inline-item">
      <button className="btn btn-sm btn-primary" disabled>Previous</button>
    </li>
  }


  let pageNumberForm = <form key={current_page} className="form-page-num" onSubmit={(e) => { eventsPageNumberSubmit(e, props.event_name, total_pages) }}>
    <input name="page" type="text" className="pagi-n-input" defaultValue={current_page} aria-label="enter page number" /></form>

  if (current_page) {
    current_page = <li className="list-inline-item active" aria-current="page">
      <span className="">
        {pageNumberForm} of {total_pages}
        <span className="sr-only">(current)</span>
      </span>
    </li>
  }

  return <nav key={nKey}>
    <ul className="list-inline my-3">
      {previous_page}
      {current_page}
      {next_page}
    </ul>
  </nav>

}

function pageNumberSubmit(e, href, search_query, page_query, asPath, order, totalPages) {
  e.preventDefault()
  let requirePageNumber = e.target.page.value;

  let current_page = parseInt(requirePageNumber) || 0
  let total_Pages = parseInt(totalPages) || 0

  if (!isNaN(requirePageNumber) && current_page <= total_Pages) {
    let p = "/" + href + search_query + page_query + requirePageNumber + order
    let aP = asPath + requirePageNumber + order
    Router.push(p, aP)
  }
}


export function CategoryPagination(props) {

  let previous_page = props.previous_page
  let next_page = props.next_page
  let current_page = props.current_page
  let total_pages = props.total_pages
  let href = props.href
  let nKey = new Date()
  let order = ""
  let category = props.category
  let page_query
  let search_query = ""
  let asPath = "/" + href
  if (category) {
    asPath = asPath + '/' + (category.slug) + '?page='
    search_query = "?cat=" + (category.slug)
    page_query = "&page="
  }
  else {
    asPath = asPath + '?page='
    page_query = "?page="
  }



  if (next_page) {

    next_page = <li className="list-inline-item">
      <Link href={"/" + href + search_query + page_query + next_page} as={asPath + next_page}>
        <a className="btn btn-sm btn-primary" href={"/" + asPath + next_page}>Next</a>
      </Link>
    </li>
  }
  else {
    next_page = <li className="list-inline-item ">


    </li>
  }

  if (previous_page) {

    previous_page = <li className="list-inline-item">
      <Link href={"/" + href + search_query + page_query + previous_page} as={asPath + previous_page}>
        <a className="btn btn-sm btn-primary" href={"/" + href + search_query + page_query + previous_page} >Previous</a>
      </Link>
    </li>
  }
  else {
    previous_page = <li className="list-inline-item ">

    </li>
  }


  let pageNumberForm = <form key={current_page} className="form-page-num" onSubmit={(e) => { pageNumberSubmit(e, href, search_query, page_query, asPath, order, total_pages) }}>
    <input name="page" type="text" className="pagi-n-input" defaultValue={current_page} aria-label="enter page number" /></form>


  if (current_page) {
    current_page = <li className="list-inline-item active" aria-current="page">
      <span className="">
        {pageNumberForm} of {total_pages}
        <span className="sr-only">(current)</span>
      </span>
    </li>
  }

  return <nav key={nKey}>
    <ul className="list-inline my-3">
      {previous_page}
      {current_page}
      {next_page}
    </ul>
  </nav>

}




export function SearchPagination(props) {

  let previous_page = props.previous_page
  let next_page = props.next_page
  let current_page = props.current_page
  let total_pages = props.total_pages
  let index = props.index
  let href = props.href
  let order = ""
  let version = props.version || 2
  let nKey = new Date()
  let search_term = props.search_term
  let page_query
  let search_query = "", index_query = ""
  let asPath = "/" + href
  if (search_term && index && index == "v5") {
    asPath = asPath + '/' + slugify(search_term, '+') + '?index=' + index + '&page='
    search_query = "?q=" + slugify(search_term, '+')
    index_query = "&index=" + index
    page_query = "&page="
  }
  else if (search_term) {
    asPath = asPath + '/' + slugify(search_term, '+') + '?page='
    search_query = "?q=" + slugify(search_term, '+')
    page_query = "&page="
  }
  else {
    asPath = asPath + '?page='
    page_query = "?page="
  }


  if (next_page) {

    next_page = <li className="list-inline-item">
      <Link href={"/" + href + search_query + index_query + page_query + next_page} as={asPath + next_page}>
        <a className="btn btn-sm btn-primary" href={"/" + href + search_query + index_query + page_query + next_page}>Next</a>
      </Link>
    </li>
  }
  else {
    next_page = <li className="list-inline-item ">

    </li>
  }

  if (previous_page) {

    previous_page = <li className="list-inline-item">
      <Link href={"/" + href + search_query + index_query + page_query + previous_page} as={asPath + previous_page}>
        <a className="btn btn-sm btn-primary" href={"/" + href + search_query + index_query + page_query + previous_page} >Previous</a>
      </Link>
    </li>
  }
  else {
    previous_page = <li className="list-inline-item ">

    </li>
  }

  let pageNumberForm = <form key={current_page} className="form-page-num" onSubmit={(e) => { pageNumberSubmit(e, href, search_query, page_query, asPath, order, total_pages) }}>
    <input name="page" type="text" className="pagi-n-input" defaultValue={current_page} aria-label="enter page number" /></form>


  if (current_page) {
    current_page = <li className="list-inline-item active" aria-current="page">
      <span className="">
        {pageNumberForm} of {total_pages}
      </span>
    </li>
  }

  return <nav key={nKey}>
    <ul className="list-inline my-3">
      {previous_page}
      {current_page}
      {next_page}
    </ul>
  </nav>

}
