import { useEffect } from 'react'
import Head from 'next/head'
import { SITE_URL, ASSET_URL, ASSET_PROFILE_URL } from '../vars'
import moment from 'moment'
import titleCase from 'title-case'
export function SeoMetaVideo(props) {

  const formatVideoLength = (length) => {


    return moment.duration(length, 'seconds').toISOString()
  }


  let video = props.video
  let browser_title = "Video of " + video.title + "-" + video.vid + "-Picxy"
  let meta_description = (video.description && video.description.length) ? (video.description + ' ' + video.vid) : (video.title + ' ' + video.vid)
  const router = props.router
  let url = SITE_URL + router.asPath

  const photographerName = video.photographer.first_name + " " + video.photographer.last_name

  const authorUrl = "https://www.picxy.com/" + video.photographer.username + "/videos"

  let social_description = "Get this stock video by " + photographerName + " or similar royalty free stock videos and footages at affordable price on Picxy"
  let length = formatVideoLength(video.length)
  let quality = video.variants.indexOf('4k') > -1 ? "4K" : "HD"

  return <Head>
    <title>{browser_title}</title>
    <meta name="name" content={browser_title} />
    <meta name="description" content={meta_description} />
    <meta name="image" content={video.thumbs.jpeg_800} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={browser_title} />
    <meta property="og:description" content={social_description} />
    <meta property="og:image" content={video.thumbs.jpeg_800} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={browser_title} />
    <meta name="twitter:description" content={social_description} />
    <meta name="twitter:image" content={video.thumbs.jpeg_800} />
    <meta name="twitter:site" content="@picxydotcom" />

    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {
        "@context":"http://schema.org",
        "@type":"WebSite",
        "url":"https://www.picxy.com/videos/",
        "potentialAction":{
          "@type":"SearchAction",
          "target":"https://www.picxy.com/videos/{q}/","query-input":"required name=q"}
      }`
    }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {
        "@context":"http://schema.org",
        "@type":"Organization",
        "name":"Picxy",
        "url":"https://www.picxy.com/",
        "logo":"https://images.picxy.com/assets/picxy-logo-300.png",
        "sameAs":["https://www.facebook.com/picxydotcom/","https://twitter.com/picxydotcom","https://www.linkedin.com/company/11191201/","https://www.instagram.com/picxydotcom/"]}`
    }} />

    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {"@context":"http://schema.org",
        "@type":"VideoObject",
        "name":"${browser_title}",
        "description":"${meta_description}",
        "url":"${url}",
        "thumbnailUrl":"${video.thumbs.jpeg_800}",
        "playerType":"HTML5",
        "videoQuality":"${quality}",
        "duration":"${length}",
        "contentUrl":"${video.preview}",
        "uploadDate":"${video.created_at}",
        "author":{"@type":"Person","name":"${video.photographer.username}","url":"${authorUrl}"}}`
    }} />



  </Head>

}
export function SeoMetaPhoto(props) {
  let photo = props.photo
  let browser_title = "Image of " + photo.title + "-" + photo.pid + "-Picxy"
  let meta_description = (photo.description && photo.description.length > photo.title.length) ? photo.description : (photo.title + ' ' + photo.pid)
  const router = props.router
  let url = SITE_URL + router.asPath
  let licenseContentUrl = photo.thumbnail_large
  let thumbnailUrl = photo.thumbnail_medium

  const photographerName = photo.photographer.first_name + " " + photo.photographer.last_name

  let social_description = "Get this stock photo by " + photographerName + " or similar royalty free images at affordable price on Picxy"
  let username = photo.photographer.username
  return <Head>
    <title>{browser_title}</title>
    <meta name="name" content={browser_title} />
    <meta name="description" content={meta_description} />
    <meta name="image" content={photo.thumbnail_medium} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={browser_title} />
    <meta property="og:description" content={social_description} />
    <meta property="og:image" content={photo.thumbnail_medium} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={browser_title} />
    <meta name="twitter:description" content={social_description} />
    <meta name="twitter:image" content={photo.thumbnail_medium} />
    <meta name="twitter:site" content="@picxydotcom" />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
        {
          "@context":"http://schema.org",
          "@type":"WebSite",
          "url":"https://www.picxy.com/",
          "potentialAction":{
            "@type":"SearchAction",
            "target":"https://www.picxy.com/search/{q}/","query-input":"required name=q"}
        }`
    }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
        {
          "@context":"http://schema.org",
          "@type":"Organization",
          "name":"Picxy",
          "url":"https://www.picxy.com/",
          "logo":"https://images.picxy.com/assets/picxy-logo-300.png",
          "sameAs":["https://www.facebook.com/picxydotcom/","https://twitter.com/picxydotcom","https://www.linkedin.com/company/11191201/","https://www.instagram.com/picxydotcom/"]}`
    }} />
    <script type='application/ld+json' dangerouslySetInnerHTML={{
      __html: `
        {
          "@context": "http://www.schema.org",
          "@type": "ImageObject",
          "author": {"@type": "Person", "name": "${username}"},
          "name": "${browser_title}",
          "description": "${meta_description}",
          "contentUrl": "${licenseContentUrl}",
          "url": "${url}",
          "thumbnailUrl": "${thumbnailUrl}",
          "acquireLicensePage": "${url}",
          "license": "https://www.picxy.com/license_agreement",
          "fileFormat": "image/jpeg",
          "sourceOrganization": {"@type": "Organization", "name": "Picxy.com"}
        }
        `}} />
  </Head>

}

export function SeoMetaPhotos(props) {


  let browser_title = "Indian stock pictures, Explore Asian & Southern east asian images"


  browser_title = titleCase(browser_title)

  let meta_description = "Purchase Indian stock pictures from best photographers across India. Download quality images at affordable rates - Picxy"

  const router = props.router
  let url = SITE_URL + router.asPath

  let photo = props.photos[0]

  return <Head>
    <title>{browser_title}</title>
    <meta name="name" content={browser_title} />
    <meta name="description" content={meta_description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={browser_title} />
    <meta property="og:description" content={meta_description} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={browser_title} />
    <meta name="twitter:description" content={meta_description} />
    <meta name="twitter:site" content="@picxydotcom" />

  </Head>
}


export function SeoMetaBookPhotographer(props) {

  useEffect(() => {

  }, [browser_title, meta_description, url]);

  let browser_title = "Photographer for Hire in India"


  browser_title = titleCase(browser_title)

  let meta_name = "Book A Photographer"
  let meta_description = "Leading Photography Booking Platform, Send a message on Book your Service."

  const router = props.router
  let url = SITE_URL + router.asPath

  return <Head>
    <title>{browser_title}</title>
    <meta name="name" content={meta_name} />
    <meta name="description" content={meta_description} />
    <meta name="image" content="https://www.picxy.com/static/imgv1/picxy_home_2.jpg" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={meta_name} />
    <meta property="og:description" content={meta_description} />
    <meta property="og:image" content="https://www.picxy.com/static/imgv1/picxy_home_2.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={meta_name} />
    <meta name="twitter:description" content={meta_description} />
    <meta name="twitter:image" content="https://www.picxy.com/static/imgv1/picxy_home_2.jpg" />
    <meta name="twitter:site" content="@picxydotcom" />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
    {
      "@context":"http://schema.org",
      "@type":"WebSite",
      "url":"https://www.picxy.com/",
      "potentialAction":{
        "@type":"SearchAction",
        "target":"https://www.picxy.com/search/{q}/","query-input":"required name=q"}
    }`
    }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
    {
      "@context":"http://schema.org",
      "@type":"Organization",
      "name":"Picxy",
      "url":"https://www.picxy.com/",
      "logo":"https://images.picxy.com/assets/picxy-logo-300.png",
      "sameAs":["https://www.facebook.com/picxydotcom/","https://twitter.com/picxydotcom","https://www.linkedin.com/company/11191201/","https://www.instagram.com/picxydotcom/"]}`
    }} />
  </Head>
}


export function SeoMetaVideos(props) {


  let browser_title = "Royalty Free Indian Stock Videos Clips and Footages - Picxy"


  browser_title = titleCase(browser_title)

  let meta_description = "Purchase 4k stock videos and HD video clips from the best creators of India at affordable rates - Picxy"

  const router = props.router
  let url = SITE_URL + router.asPath
  if (props.q) {
    browser_title = titleCase(props.q + " stock videos and footages - Picxy")
    meta_description = "Download " + props.q + " videos, footages and clips at affordable rates - Picxy"
  }

  return <Head>
    <title>{browser_title}</title>
    <meta name="name" content={browser_title} />
    <meta name="description" content={meta_description} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={browser_title} />
    <meta property="og:description" content={meta_description} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={browser_title} />
    <meta name="twitter:description" content={meta_description} />
    <meta name="twitter:site" content="@picxydotcom" />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {
        "@context":"http://schema.org",
        "@type":"WebSite",
        "url":"https://www.picxy.com/",
        "potentialAction":{
          "@type":"SearchAction",
          "target":"https://www.picxy.com/search/{q}/","query-input":"required name=q"}
      }`
    }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {
        "@context":"http://schema.org",
        "@type":"Organization",
        "name":"Picxy",
        "url":"https://www.picxy.com/",
        "logo":"https://images.picxy.com/assets/picxy-logo-300.png",
        "sameAs":["https://www.facebook.com/picxydotcom/","https://twitter.com/picxydotcom","https://www.linkedin.com/company/11191201/","https://www.instagram.com/picxydotcom/"]}`
    }} />
  </Head>
}


export function SeoMetaSearch(props) {

  let search_term = props.search_term

  let browser_title = " stock photos on Picxy"
  if (search_term) {
    browser_title = search_term + " " + browser_title
    if (props.total_results_count) {
      browser_title = Math.floor(props.total_results_count) + "+ " + browser_title
    }
  }

  browser_title = titleCase(browser_title)

  let meta_description = "Explore " + search_term + " stock photos on picxy. All Images are available for Digital Use  Print Use  at very low cost."

  const router = props.router
  let url = SITE_URL + router.asPath

  let photo = props.photos[0]
  let meta_title = browser_title
  if (props.current_page && props.current_page > 1) {
    browser_title = browser_title + ' Page ' + props.current_page
    meta_description = "Explore " + search_term + " stock photos on picxy. All Images are available for Digital Use Print Use at very low cost. | page " + props.current_page
  }

  if (photo) {

    return <Head>
      <title>{browser_title}</title>
      <meta name="name" content={browser_title} />
      <meta name="image" content={photo.thumbnail_medium} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={meta_title} />
      <meta property="og:description" content={meta_description} />
      <meta property="og:image" content={photo.thumbnail_medium} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={meta_title} />
      <meta name="twitter:description" content={meta_description} />
      <meta name="twitter:image" content={photo.thumbnail_medium} />
      <meta name="twitter:site" content="@picxydotcom" />
    </Head>

  }
  else {
    return null
  }
}

export function SeoMetaCollections(props) {

  let browser_title = "License Stock Collections"
  let meta_description = "All file types & HD. Download Royalty free stock photos, Unleash your creativity with this."

  let photo = props.collections[0].preview_photo
  const router = props.router
  let url = SITE_URL + router.asPath

  if (props.current_page && props.current_page > 1) {
    browser_title = browser_title + ' page ' + props.current_page
    meta_description = meta_description + "| page " + props.current_page
  }

  return <Head>
    <title>{browser_title}</title>
    <meta name="name" content={browser_title} />
    <meta name="description" content={meta_description} />
    <meta name="image" content={photo.thumbnail_medium} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={browser_title} />
    <meta property="og:description" content={meta_description} />
    <meta property="og:image" content={photo.thumbnail_medium} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={browser_title} />
    <meta name="twitter:description" content={meta_description} />
    <meta name="twitter:image" content={photo.thumbnail_medium} />
    <meta name="twitter:site" content="@picxydotcom" />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {
        "@context":"http://schema.org",
        "@type":"WebSite",
        "url":"https://www.picxy.com/",
        "potentialAction":{
          "@type":"SearchAction",
          "target":"https://www.picxy.com/search/{q}/","query-input":"required name=q"}
      }`
    }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {
        "@context":"http://schema.org",
        "@type":"Organization",
        "name":"Picxy",
        "url":"https://www.picxy.com/",
        "logo":"https://images.picxy.com/assets/picxy-logo-300.png",
        "sameAs":["https://www.facebook.com/picxydotcom/","https://twitter.com/picxydotcom","https://www.linkedin.com/company/11191201/","https://www.instagram.com/picxydotcom/"]}`
    }} />
  </Head>

}
export function SeoMetaCollection(props) {

  let collection = props.collection

  let browser_title = collection.name + ' images | ' + props.count + ' HD pictures and stock photos'
  if ([83, 51, 46, 45, 30, 68, 107].indexOf(collection.id) <= -1) {
    browser_title = "Download " + browser_title
  }



  let meta_description = "Looking for high quality " + collection.name + " images? Browse Picxy's high quality collection of " + props.count + "+ " + collection.name + " pictures and stock photos now! ✓High Quality images ✓ Multiple resolution options ✓100% genuine photos"

  const router = props.router
  let url = SITE_URL + router.asPath

  let photo = props.photos[0]

  if (props.current_page && props.current_page > 1) {
    browser_title = browser_title + ' page ' + props.current_page
    meta_description = meta_description + "| page " + props.current_page
  }

  let licenses = props.photos.map((p) => {

    const acqLicensePage = SITE_URL + '/photo/' + p.id
    return {
      "@context": "http://www.schema.org",
      "@type": "ImageObject",
      "contentUrl": p.thumbnail_large,
      "name": p.title + " " + p.pid,
      "acquireLicensePage": acqLicensePage,
      "license": "https://www.picxy.com/license_agreement",
    }
  })



  return <Head>

    <title>{browser_title}</title>
    <meta name="name" content={browser_title} />
    <meta name="description" content={meta_description} />
    <meta name="image" content={photo.thumbnail_medium} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={browser_title} />
    <meta property="og:description" content={meta_description} />
    <meta property="og:image" content={photo.thumbnail_medium} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={browser_title} />
    <meta name="twitter:description" content={meta_description} />
    <meta name="twitter:image" content={photo.thumbnail_medium} />
    <meta name="twitter:site" content="@picxydotcom" />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {
        "@context":"http://schema.org",
        "@type":"WebSite",
        "url":"https://www.picxy.com/",
        "potentialAction":{
          "@type":"SearchAction",
          "target":"https://www.picxy.com/search/{q}/","query-input":"required name=q"}
      }`
    }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
      {
        "@context":"http://schema.org",
        "@type":"Organization",
        "name":"Picxy",
        "url":"https://www.picxy.com/",
        "logo":"https://images.picxy.com/assets/picxy-logo-300.png",
        "sameAs":["https://www.facebook.com/picxydotcom/","https://twitter.com/picxydotcom","https://www.linkedin.com/company/11191201/","https://www.instagram.com/picxydotcom/"]}`
    }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `${JSON.stringify(licenses)}
      `}} />
  </Head>
}

export function SeoMetaIndex(props) {
  let meta_name = "Indian stock photo website|Explore Indian stock Images from photographers across India"
  let meta_description = "Purchase high quality Indian Stock photos from picxy. 𝗪𝗲 𝗮𝗿𝗲 𝘀𝗼𝘂𝘁𝗵 𝗔𝘀𝗶𝗮'𝘀 𝗯𝗶𝗴𝗴𝗲𝘀𝘁 𝘀𝘁𝗼𝗰𝗸 𝗶𝗺𝗮𝗴𝗲 𝗰𝗼𝗺𝗽𝗮𝗻𝘆."
  const router = props.router
  let url = SITE_URL + router.asPath

  return <Head>
    <meta name="name" content={meta_name} />
    <meta name="description" content={meta_description} />
    <meta name="image" content="https://www.picxy.com/static/imgv1/picxy_home_2.jpg" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={meta_name} />
    <meta property="og:description" content={meta_description} />
    <meta property="og:image" content="https://www.picxy.com/static/imgv1/picxy_home_2.jpg" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={meta_name} />
    <meta name="twitter:description" content={meta_description} />
    <meta name="twitter:image" content="https://www.picxy.com/static/imgv1/picxy_home_2.jpg" />
    <meta name="twitter:site" content="@picxydotcom" />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
    {
      "@context":"http://schema.org",
      "@type":"WebSite",
      "url":"https://www.picxy.com/",
      "potentialAction":{
        "@type":"SearchAction",
        "target":"https://www.picxy.com/search/{q}/","query-input":"required name=q"}
    }`
    }} />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
    {
      "@context":"http://schema.org",
      "@type":"Organization",
      "name":"Picxy",
      "url":"https://www.picxy.com/",
      "logo":"https://images.picxy.com/assets/picxy-logo-300.png",
      "sameAs":["https://www.facebook.com/picxydotcom/","https://twitter.com/picxydotcom","https://www.linkedin.com/company/11191201/","https://www.instagram.com/picxydotcom/"]}`
    }} />
  </Head>
}

export function SeoMetaPhotographer(props) {
  let photographer = props.contributor_info.contributor


  let photographerName = photographer.first_name + ' ' + photographer.last_name

  if (!photographerName) {
    photographerName = photographer.username
  }

  let meta_name = (photographerName) + ' profile on Picxy'
  if (props.contributorPhotosContext) {
    meta_name = (photographerName) + ' photos on Picxy'
  }
  else if (props.contributorVideosContext) {
    meta_name = (photographerName) + ' videos on Picxy'
  }

  if (props.current_page && props.current_page > 1) {
    meta_name = meta_name + ' page ' + props.current_page
  }

  let meta_description = "Explore the best shots of " + photographerName + ' on Picxy'
  if (photographer.profile.location) {
    meta_description = photographerName + ' from ' + photographer.profile.location + ", member since " + moment(photographer.date_joined).toNow(true)
  }
  if (photographer.profile.bio) {
    meta_description = meta_description + ', ' + photographer.profile.bio
  }
  if (props.current_page && props.current_page > 1) {
    meta_description = meta_description + ' | page ' + props.current_page
  }
  const router = props.router
  let url = SITE_URL + router.asPath

  let profile_pic = (photographer.profile.thumbnail_small && photographer.profile.thumbnail_small !== "null") ? photographer.profile.thumbnail_small : 'imgv1/mysteryman.png'
  profile_pic = ASSET_PROFILE_URL + profile_pic
  return <Head>
    <title>{meta_name}</title>
    <meta name="name" content={meta_name} />
    <meta name="description" content={meta_description} />
    <meta name="image" content={profile_pic} />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={meta_name} />
    <meta property="og:description" content={meta_description} />
    <meta property="og:image" content={profile_pic} />
  </Head>
}

export function SeoMetaBasic(props) {

  let meta_name = props.name
  let meta_description = props.description

  const router = props.router
  let url = SITE_URL + router.asPath

  let meta_image = props.image ? props.image : "https://www.picxy.com/static/imgv1/picxy_home_2.jpg"

  return <Head>
    <meta name="name" content={meta_name} />
    <meta name="description" content={meta_description} />
    <meta name="image" content={meta_image} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={meta_name} />
    <meta property="og:description" content={meta_description} />
    <meta property="og:image" content={meta_image} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={meta_name} />
    <meta name="twitter:description" content={meta_description} />
    <meta name="twitter:image" content={meta_image} />
    <meta name="twitter:site" content="@picxydotcom" />
  </Head>
}

export function SeoMetaPricing(props) {
  // let meta_name = "Pricing"
  let browser_title = "Stock Photos, Royalty-free License Prices & Plans"
  let meta_description = "Get the best prices on royalty-free stock images tailored to your needs."

  const router = props.router
  let url = SITE_URL + router.asPath

  let meta_image = props.image ? props.image : "https://www.picxy.com/static/imgv1/picxy_home_2.jpg"

  return <Head>
    <title>{browser_title}</title>
    <meta name="name" content={browser_title} />
    <meta name="description" content={meta_description} />
    <meta name="image" content={meta_image} />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={browser_title} />
    <meta property="og:description" content={meta_description} />
    <meta property="og:image" content={meta_image} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={browser_title} />
    <meta name="twitter:description" content={meta_description} />
    <meta name="twitter:image" content={meta_image} />
    <meta name="twitter:site" content="@picxydotcom" />
    <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: `
    {
      "@context":"http://schema.org",
      "@type":"Organization",
      "name":"Picxy",
      "url":"https://www.picxy.com/",
      "logo":"https://images.picxy.com/assets/picxy-logo-300.png",
      "sameAs":["https://www.facebook.com/picxydotcom/","https://twitter.com/picxydotcom","https://www.linkedin.com/company/11191201/","https://www.instagram.com/picxydotcom/"]}`
    }} />

    <script type='application/ld+json' dangerouslySetInnerHTML={{
      __html: `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "How are credits debited?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "If image is downloaded under <b>digital usage, it is 1 credit</b>. If it is downloaded under <b>print usage it is 10 credits.</b>"
          }
        }, {
          "@type": "Question",
          "name": "Are the remaining credits carried to next month?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Remaning credits will be <b>carried to next month, as long as the subscription is active.</b>"
          }
        }, {
          "@type": "Question",
          "name": "What is value pack?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Download images anytime within 6 months of purchase.</b>"
          }
        }, {
          "@type": "Question",
          "name": "What is quarterly plan?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "A quarterly plan is a 3 months recurring subsciption that enables you to save cost."
          }
        }, {
          "@type": "Question",
          "name": "Can I subscribe to multiple plans?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No, at present we are allowing only one active plan at a time."
          }
        }, {
          "@type": "Question",
          "name": "How can I upgrade to new plan?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Please reach  to us to upgrade your plan."
          }
        }, {
          "@type": "Question",
          "name": "Can I cancel the plan when not needed?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "You can cancel the plan anytime. Your recurring payments will be stopped."
          }
        }, {
          "@type": "Question",
          "name": "Will I get refunded if I cancel the subscription?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text":"No refunds will be issued on the current subscription. The future recurring payments will be cancelled."}
          }, {
            "@type": "Question",
            "name": "What happens to my credits if I cancel?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text":"You are good to use those credits until the end of the validty of current subscription."}
            }]
      }
    `}} />

  </Head>
}