
import React from 'react'
import Router from 'next/router'
import slugify from 'slugify'
import { throttle, debounce } from "throttle-debounce";
import {RECENT_SEARCH_TERMS, CURRENT_SEARCH_TERM, SEARCH_SUGGEST_API, SEARCH_COLLECTION_API} from '../vars'
import {GET, POST, UPOST} from '../helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Autosuggest from 'react-autosuggest';
import {logEvent} from './analytics'

const suggestionStyles = {
  container:                'react-autosuggest__container',
  containerOpen:            'react-autosuggest__container--open',
  input:                    'form-control form-control-lg',
  inputOpen:                'form-control form-control-lg',
  inputFocused:             'react-autosuggest__input--focused',
  suggestionsContainer:     'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  suggestionsList:          'react-autosuggest__suggestions-list',
  suggestion:               'react-autosuggest__suggestion',
  suggestionFirst:          'react-autosuggest__suggestion--first',
  suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
  sectionContainer:         'react-autosuggest__section-container',
  sectionContainerFirst:    'react-autosuggest__section-container--first',
  sectionTitle:             'react-autosuggest__section-title'
}
export default class SuggestionEngine extends React.Component {

  constructor(props){
    super(props)
    this.state = {'fetching_search_suggestions': false, suggestions:[], q: props.search_term || ''}
  }


  search = (e, q, gotoPage=false)=> {
    console.log("search",q);
    let q_term = q ? q: this.state.q

    e.preventDefault()
    if(q_term.length) {
      let query_term = slugify(q_term, '+')
      // collection search
      if (this.props.type == "collection") {

        // if collection found, take to collection page
        // else take to collection search page
        if (gotoPage) {
          query_term = slugify(q_term, '-')
          Router.push('/collections?collection_slug='+query_term, '/collections/'+query_term)
        }
        else {
          logEvent("Collection", "Search", q_term)
          Router.push('/collections?search='+query_term, undefined, { shallow: false })
        }
      }
      else {
        Router.push('/search?q='+query_term, '/search/'+query_term)
      }
    }

  }

  getSearchSuggestions  =(q)=>{
    this.setState({fetching_search_suggestions:true})
    if (this.props.type == "collection"){
      return UPOST(SEARCH_COLLECTION_API,{
        "query":{
          "bool":{
            "should":[
              {
                "bool":{
                  "should":[
                    {
                      "match_phrase": {
                        "name.search":q.value
                      },
                    },
                    {
                      "match_phrase_prefix": {
                        "name_std":q.value
                      }
                    }
                  ],
                }
              }
            ]
          },
        },
        "_source":["name","image"],
        "size":25

      }).then((res)=>{
        let options = [{"value":q.value,"label":q.value, "id":"search_term"}]
        let options_search = res.data.hits.hits.map((col,i)=>{
          return {
              "value": col._source['name'],
              "label": col._source['name'],
              "id": col._id,
              "image":col._source['image']
          }
        })
        this.setState({suggestions:options.concat(options_search), fetching_search_suggestions:false})
      }).catch((err)=>{
        this.setState({fetching_search_suggestions:false})
      })
    }
    else {
      return UPOST(SEARCH_SUGGEST_API, {
      "suggest": {
          "keywords" : {
              "prefix" : q.value,
              "completion" : {
                  "field" : "name",
              }
          }
      }
      }).then((response)=>{
        if(response.data.suggest.keywords[0].options) {
          let options = [{"value":q.value,"label":q.value, "id":"search_term"}]
          let options_search = response.data.suggest.keywords[0].options.map((item,i)=>{
            return {
                "value": item.text,
                "label": item.text,
                "id": item._id
            }
          })

          this.setState({suggestions:options.concat(options_search), fetching_search_suggestions:false})
        }
      }).catch((error)=>{
        console.log(error);
        this.setState({fetching_search_suggestions:false})
      })
    }
  }

  handleInputChange = debounce(50, (input)=>{
    this.getSearchSuggestions(input)
  })
  onSuggestionSelected = (e, {suggestion, value, index,sectionIndex, method})=>{

    if (method=="click" || method == "enter") {

      logEvent("Collection","Search Suggestion", suggestion.value)

      let goto_collection = suggestion.id !=="search_term"?true:false
      this.search(e, suggestion.value, goto_collection)
    }

  }
  onChange = (event, { newValue }) => {
    this.setState({
      q: newValue
    });
  };

  getSuggestionValue = (suggest)=>{
    return suggest.value
  }

  onSuggestionsClearRequested = () => {
   this.setState({
     suggestions: []
   });
 };


  renderSuggestion = (suggest)=>{
    let render = <div key={suggest.id}>
      {suggest.value}
    </div>
    if (suggest.id === "search_term") {
      render = <div key={suggest.id}>
        <FontAwesomeIcon icon={faSearch} title="Search Photos" className="text-muted" /> {suggest.value}
        <span className="text-muted ml-2">Search Photos</span>
      </div>
    }

    if (this.props.type == "collection") {
      let title = suggest.id === "search_term"?"Search Collections":"Go to Collection"

      render = <div key={suggest.id} title={title}>
          <img src={suggest.image} className="image-fluid rounded mr-2" width="35"/>
          {suggest.value}
      </div>

      if (suggest.id === "search_term") {
        render = <div key={suggest.id} title={title}>
          <FontAwesomeIcon icon={faSearch} title="Search Collection" className="text-muted" /> {suggest.value}
          <span className="text-muted ml-2">Search Collection</span>
        </div>
      }
    }
    return <>{render}</>
  }

  shouldRenderSuggestions  = (q)=>{
    return q.trim().length > 1;
  }


  render() {
    let placeholder = this.props.type=="collection"?"Search Collection":"Search Photos"
    return <div>
    <form id="searchPhotosForm" onSubmit={this.search} >
    <Autosuggest
       suggestions={this.state.suggestions}
       onSuggestionsFetchRequested={this.handleInputChange}
       onSuggestionsClearRequested = {this.onSuggestionsClearRequested}
       getSuggestionValue={this.getSuggestionValue}
       renderSuggestion={this.renderSuggestion}
       shouldRenderSuggestions={this.shouldRenderSuggestions}
       onSuggestionSelected = {this.onSuggestionSelected}
       inputProps={{

        placeholder:placeholder,
         value:this.state.q,
         onChange : this.onChange,
       }}
     />
</form>

    </div>
  }
}
